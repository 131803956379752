import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { theme } from './kit/theme/theme';
import store from './redux/store';
import { getUser } from './redux/reducers/userSlice';
import { setUtmToCookie } from './helpers/utmCookie';
import IndexPage from './pages';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from './pages/404';

export default function App() {
  useEffect(() => {
    store.dispatch(getUser.trigger());
    setUtmToCookie();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ThemeProvider>
    </Provider>
  );
}
