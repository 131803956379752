const styles = {
  footerWrapper: {
    backgroundColor: 'kit.background.purple',
    padding: {
      xs: '32px 0 38px',
      xl: '40px 0',
    },
  },
  columnHeader: {
    marginBottom: 4,
  },
  firstColumn: {
    order: {
      xl: 1,
      md: 1,
      xs: 1,
    },
  },
  secondColumn: {
    order: {
      xl: 2,
      md: 2,
      xs: 3,
    },
  },
  thirdColumn: {
    order: {
      xl: 3,
      md: 5,
      xs: 5,
    },
  },
  fourthColumn: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
    order: {
      xl: 4,
      md: 3,
      xs: 2,
    },
    marginBottom: {
      xs: 5,
      lg: 0,
    },
    '& > a': {
      lineHeight: 0,
    },
  },
  fifthColumn: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
    order: {
      xl: 5,
      md: 4,
      xs: 4,
    },
    marginTop: {
      xs: 5,
      xl: 0,
    },
  },
  sixthColumn: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
    order: {
      xl: 6,
      md: 6,
      xs: 6,
    },
    marginTop: {
      xs: 6,
    },
  },
  rightWrapper: {
    height: '100%',
  },
  linkWrapper: {
    fontSize: '14px',
    marginBottom: '12px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  linkItem: {
    display: 'inline-block',
    '&::after': {
      content: "'/'",
      color: 'kit.link.inverseWhite',
      opacity: 0.6,
    },
    '&:last-child': {
      '&::after': {
        content: "''",
      },
    },
  },
  link: {
    fontSize: {
      xs: '14px',
      md: '16px',
    },
    lineHeight: '20px',
    display: 'inline-block',
  },
  mapInfo: {
    fontSize: {
      xs: '14px',
      md: '16px',
    },
    lineHeight: {
      xs: '16px',
      md: '20px',
    },
  },
  addressContainer: {
    my: {
      xs: 4,
      lg: 3,
    },
  },
  mapIconContainer: {
    width: '24px',
    marginLeft: '5px',
  },
  social: {
    lineHeight: 0,
  },
  socialIcon: {
    color: 'transparent',
    width: '40px',
    height: '40px',
    opacity: 1,
  },
  subscription: {
    marginBottom: {
      xs: '32px',
    },
  },
  icon: { width: '40px', height: '40px' },
  logo: {
    width: '178px',
    height: 'auto',
  },
};

export default styles;
