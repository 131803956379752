import { Components, Theme } from '@mui/material';

export const muiAccordionSummaryTheme: Components<Theme> = {
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        color: theme.palette.kit.text.secondary,
        backgroundColor: theme.palette.kit.background.lightpurple,
        flexDirection: 'row',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(-90deg)',
          color: theme.palette.kit.text.h,
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: 24,
        },
        '& .Mui-expanded': {
          color: theme.palette.kit.text.h,
        },
        '&.MuiButtonBase-root.MuiAccordionSummary-root': {
          flexDirection: 'row',
        },
      }),
    },
  },
};
