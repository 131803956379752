import { SvgIcon, SvgIconProps } from '@mui/material';

export function TelegramOutlinedIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        d="M0.5 16C0.5 7.43959 7.43959 0.5 16 0.5H24C32.5604 0.5 39.5 7.43959 39.5 16V24C39.5 32.5604 32.5604 39.5 24 39.5H16C7.43959 39.5 0.5 32.5604 0.5 24V16Z"
        stroke="white"
      />
      <path
        d="M29.943 14.3656L26.9249 27.0945C26.6972 27.9929 26.1034 28.2165 25.2596 27.7933L20.6609 24.7628L18.442 26.6713C18.1964 26.8909 17.9911 27.0746 17.5178 27.0746L17.8482 22.8861L26.3713 15.9986C26.7418 15.7031 26.2909 15.5394 25.7953 15.8349L15.2587 21.7682L10.7225 20.4985C9.73585 20.223 9.718 19.6161 10.9279 19.1928L28.6706 13.0799C29.4921 12.8044 30.2109 13.2436 29.943 14.3656V14.3656Z"
        stroke="white"
      />
    </SvgIcon>
  );
}
