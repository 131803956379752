import { Components, Theme } from '@mui/material';

export const muiStepLabelTheme: Components<Theme> = {
  MuiStepLabel: {
    styleOverrides: {
      label: {
        '&.MuiStepLabel-alternativeLabel': {
          marginTop: 8,
        },
      },
    },
  },
};
