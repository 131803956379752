import React, { useEffect } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { arrLinks } from '../Navbar/Navbar';
import { styles } from './styles';
import Link from '../../../kit/Link/Link';

export default function BurgerMenu({ lkLink }: any) {
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isOpen = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setBurgerMenuIsOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setBurgerMenuIsOpen(false);
  };

  useEffect(() => {
    window?.addEventListener('scroll', handleClose);
    return () => {
      window?.removeEventListener('scroll', handleClose);
    };
  }, []);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box onClick={handleClick} sx={styles.burgerMenu(burgerMenuIsOpen)} />

      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        anchorReference="anchorPosition"
        //@ts-ignore
        anchorPosition={{ top: 48 }}
        keepMounted
        disableAutoFocusItem
        sx={{
          '.MuiMenu-paper': {
            maxWidth: 'none',
            width: '100%',
          },
        }}
      >
        <Box sx={{ boxSizing: 'border-box', ml: 'auto', my: 2 }}>
          <Link
            key={lkLink}
            variant="kitSecondary"
            target="_blank"
            underline="hover"
            to={lkLink}
            sx={styles.link}
          >
            <Button variant={'outlined'} size={'smallest'} sx={{ px: 1.5 }}>
              Тренажер для поступления{' '}
            </Button>
          </Link>
        </Box>
        {arrLinks.map((link) => {
          return (
            <MenuItem key={link.title}>
              <Link
                to={link.href}
                sx={{
                  ':hover': {
                    textDecoration: 'none',
                  },
                }}
                variant="kitSecondary"
                underline="none"
              >
                {link.title}
              </Link>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}
