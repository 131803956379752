import { Components, Theme } from '@mui/material';

export const muiAppBarTheme: Components<Theme> = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        height: 88,
        justifyContent: 'center',
      },
    },
  },
};
