import { Theme } from '@mui/material';

const styles = {
  itemContainer: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
    gap: '12px',
    width: '100%',
    backgroundColor: '#FFF',
    color: 'kit.text.main',
    borderRadius: '8px',
    '& div': {
      flexBasis: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      flexWrap: 'wrap',
      gap: '9px 26px',
      alignItems: 'flex-start',
      '& div': {
        flexBasis: '45%',
      },
    },
  }),
  innerBlock: (theme: Theme) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      minHeight: '70px',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  }),
  title: {
    color: 'kit.text.secondary',
  },
  points: {
    width: 'max-content',
    padding: '2px 8px',
    borderRadius: '12px',
  },
  commentBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  date: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      marginTop: '2px',
    },
  }),
};

export default styles;
