import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';

export type SubscriptionLoadingState =
  | 'INITIAL'
  | 'LOADING'
  | 'SUCCESS'
  | 'FAILURE';

export interface FooterSubscriptionState {
  emailText: string;
  loadingState: SubscriptionLoadingState;
}

const initialState: FooterSubscriptionState = {
  emailText: '',
  loadingState: 'INITIAL',
};

export const subscribe = createRoutine('FOOTER_SUBSCRIBE');

export const footerSubscriptionSlice = createSlice({
  name: 'footerSubscription',
  initialState,
  reducers: {
    changeEmail: (state, { payload }: PayloadAction<string>) => {
      state.emailText = payload;
    },
  },
  extraReducers: {
    [subscribe.TRIGGER]: (state) => {
      state.loadingState = 'LOADING';
    },
    [subscribe.SUCCESS]: (state) => {
      state.loadingState = 'SUCCESS';
    },
    [subscribe.FAILURE]: (state) => {
      state.loadingState = 'FAILURE';
    },
  },
});

export const { changeEmail } = footerSubscriptionSlice.actions;

export default footerSubscriptionSlice.reducer;
