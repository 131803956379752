import { SvgIcon, SvgIconProps } from '@mui/material';

export function VkOutlinedIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
        <rect x="0.5" y="0.5" width="39" height="39" rx="15.5" stroke="white" />
        <g clipPath="url(#clip0_12847_108792)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7597 27.1873H21.041C21.041 27.1873 21.4281 27.1464 21.6283 26.9279C21.8151 26.7231 21.8018 26.3545 21.8018 26.3545C21.8018 26.3545 21.7751 24.5933 22.5759 24.3339C23.3634 24.0745 24.3777 26.0405 25.4455 26.7914C26.2596 27.3648 26.8736 27.242 26.8736 27.242L29.7432 27.201C29.7432 27.201 31.238 27.1054 30.5306 25.904C30.4772 25.8084 30.1169 25.0165 28.3951 23.3782C26.5933 21.6716 26.8336 21.9446 29.0091 18.9956C30.3304 17.1934 30.851 16.1012 30.6908 15.6233C30.5306 15.1728 29.5697 15.2957 29.5697 15.2957L26.3397 15.323C26.3397 15.323 26.0995 15.2957 25.926 15.4049C25.7525 15.5141 25.6457 15.7599 25.6457 15.7599C25.6457 15.7599 25.1385 17.1525 24.4578 18.3403C23.0164 20.8387 22.4424 20.9753 22.2155 20.8114C21.6683 20.4565 21.8018 19.3642 21.8018 18.586C21.8018 16.1695 22.1622 15.1592 21.1078 14.8997C20.7607 14.8178 20.4938 14.7496 19.5996 14.7496C18.4517 14.7359 17.4774 14.7496 16.9168 15.0363C16.5431 15.2138 16.2628 15.637 16.4364 15.6507C16.6499 15.678 17.1304 15.7872 17.384 16.1422C17.7177 16.6064 17.7043 17.6303 17.7043 17.6303C17.7043 17.6303 17.8912 20.4838 17.2639 20.8388C16.8234 21.0845 16.2362 20.5793 14.9549 18.313C14.3009 17.1525 13.807 15.8691 13.807 15.8691C13.807 15.8691 13.7136 15.6233 13.5401 15.5005C13.3399 15.3503 13.0463 15.2957 13.0463 15.2957L9.97648 15.3093C9.97648 15.3093 9.50934 15.323 9.34917 15.5278C9.20236 15.7053 9.33583 16.0875 9.33583 16.0875C9.33583 16.0875 11.7383 21.8354 14.461 24.7298C16.9302 27.3648 19.7597 27.1873 19.7597 27.1873Z"
            stroke="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_12847_108792">
            <rect
              width="22.2222"
              height="21.3333"
              fill="white"
              transform="translate(9 9)"
            />
          </clipPath>
        </defs>
    </SvgIcon>
  );
}
