export const styles = {
  burgerMenu: (burgerMenuIsOpen: boolean) => ({
    boxSizing: 'border-box',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 0,16 C 0,2.4 2.4,0 16,0 S 32,2.4 32,16 29.6,32 16,32 0,29.6 0,16' fill='%234520AB'/%3E%3C/svg%3E")`,
    borderRadius: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '32px',
    width: '32px',
    cursor: 'pointer',
    pl: 1,
    ':before, :after': {
      display: 'block',
      position: 'absolute',
      left: 6.5,
      height: '2px',
      content: '" "',
      backgroundColor: 'kit.background.white',
      width: '19px',
    },
    ':before': {
      transform: burgerMenuIsOpen ? 'rotate(45deg)' : 'rotate(0deg)',
      transition: '.2s ease-in-out',
      marginBottom: burgerMenuIsOpen ? 0 : '10px',
    },
    ':after': {
      transform: burgerMenuIsOpen ? 'rotate(-45deg)' : 'rotate(0deg)',
      transition: '.2s ease-in-out',
      marginTop: burgerMenuIsOpen ? 0 : '10px',
    },
  }),

  link: {
    textDecoration: 'none !important',
    color: 'kit.link.main',
    my: 2,
    fontSize: '14px',
  },
};
