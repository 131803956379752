import * as React from 'react';
import { SvgIcon } from '@mui/material';

export function ElbrusRusLogoIcon(props: any) {
  return (
    <SvgIcon viewBox="2 5 100 34" {...props}>
      <svg
        width="110"
        height="40"
        viewBox="0 0 110 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M30.4009 5.0004H0V35.2868H30.4009V5.0004Z" fill="#4520AB" />
        <path
          d="M7.58276 10.4121H6.40752V11.2736H7.53841V11.5829H6.38535V12.7095H7.56058V12.9967H6.05273V10.1249H7.56058V10.4121H7.58276Z"
          fill="white"
        />
        <path
          d="M12.3072 10.1249V12.7095H13.1055V12.9967H11.9746V10.1249H12.3072Z"
          fill="white"
        />
        <path
          d="M17.9182 10.1249C18.0734 10.1249 18.3617 10.147 18.5834 10.3016C18.6721 10.3679 18.8495 10.5446 18.8495 10.8981C18.8495 11.0306 18.8274 11.2957 18.5613 11.4724C18.9604 11.5829 19.1156 11.9142 19.1156 12.2014C19.1156 12.4886 18.9826 12.7095 18.8274 12.82C18.6056 12.9967 18.3395 13.0188 18.14 13.0188H17.4082V10.1249H17.9182ZM17.7186 11.3841H17.9626C18.14 11.3841 18.5391 11.3399 18.5391 10.8981C18.5391 10.4121 18.0734 10.4121 17.9847 10.4121H17.7408V11.3841H17.7186ZM17.7186 12.7095H18.0956C18.273 12.7095 18.4726 12.6874 18.6056 12.599C18.7165 12.5107 18.8052 12.3781 18.8052 12.2014C18.8052 11.9805 18.6721 11.848 18.5613 11.7817C18.4504 11.7154 18.2952 11.6933 18.0734 11.6933H17.7408V12.7095H17.7186Z"
          fill="white"
        />
        <path
          d="M23.5301 10.1249C23.7297 10.1249 24.0179 10.147 24.2397 10.2795C24.4171 10.39 24.5501 10.6109 24.5501 10.9201C24.5501 11.362 24.2619 11.6933 23.8184 11.7154L24.7497 12.9967H24.3506L23.4636 11.7375H23.3749V12.9967H23.0645V10.1249H23.5301ZM23.3749 11.4503H23.5745C23.9736 11.4503 24.2175 11.2515 24.2175 10.9201C24.2175 10.7655 24.1732 10.6109 23.9958 10.5004C23.8627 10.4341 23.7075 10.4121 23.5745 10.4121H23.3749V11.4503Z"
          fill="white"
        />
        <path
          d="M6.12099 17.5695C6.12099 17.8125 6.12099 18.0776 6.34273 18.2764C6.4536 18.3869 6.65317 18.4311 6.83056 18.4311C6.98578 18.4311 7.18535 18.3648 7.3184 18.2764C7.54014 18.0776 7.54014 17.8125 7.54014 17.5695V15.8023H7.85058V17.6579C7.85058 17.9009 7.82841 18.1439 7.67319 18.3427C7.49579 18.5857 7.18535 18.7404 6.83056 18.7404C6.4536 18.7404 6.16534 18.5857 5.98794 18.3427C5.83272 18.1218 5.81055 17.9009 5.81055 17.6579V15.8023H6.12099V17.5695V17.5695Z"
          fill="white"
        />
        <path
          d="M13.082 16.3765C13.0376 16.2881 12.9711 16.2219 12.9046 16.1556C12.838 16.1114 12.7493 16.0451 12.5719 16.0451C12.2837 16.0451 12.0841 16.2219 12.0841 16.487C12.0841 16.5974 12.1063 16.6637 12.195 16.73C12.2837 16.8183 12.3946 16.8625 12.5054 16.9067L12.7937 17.0171C12.9711 17.0834 13.1263 17.1718 13.2372 17.2822C13.3924 17.4369 13.4367 17.5915 13.4367 17.8124C13.4367 18.3426 13.0376 18.696 12.5276 18.696C12.328 18.696 12.1063 18.6518 11.9067 18.453C11.7737 18.3205 11.685 18.1217 11.6406 17.9449L11.9511 17.8566C11.9732 18.0112 12.0398 18.1438 12.1285 18.2321C12.2615 18.3647 12.3946 18.3868 12.5498 18.3868C12.9489 18.3868 13.1263 18.0996 13.1263 17.8124C13.1263 17.6799 13.082 17.5694 12.9933 17.481C12.9046 17.3927 12.7937 17.3485 12.6385 17.2822L12.3724 17.1718C12.2615 17.1276 12.1063 17.0613 11.9954 16.9288C11.8624 16.8183 11.7958 16.6637 11.7958 16.4649C11.7958 16.023 12.1285 15.7138 12.6163 15.7138C12.7937 15.7138 12.9267 15.758 13.082 15.8463C13.1928 15.9347 13.3037 16.0451 13.3702 16.1777L13.082 16.3765Z"
          fill="white"
        />
        <path
          d="M19.2278 15.4488L19.4717 15.5814L17.2986 19.4693L17.0547 19.3589L19.2278 15.4488Z"
          fill="white"
        />
        <path
          d="M6.50024 21.4795C6.65546 21.4795 6.94373 21.5016 7.16547 21.6562C7.25417 21.7225 7.43156 21.8992 7.43156 22.2526C7.43156 22.3852 7.40939 22.6503 7.1433 22.827C7.54243 22.9375 7.69765 23.2688 7.69765 23.556C7.69765 23.8432 7.56461 24.0641 7.40939 24.1745C7.18765 24.3513 6.92155 24.3734 6.72199 24.3734H5.99023V21.4795H6.50024ZM6.30067 22.7386H6.54459C6.72199 22.7386 7.12112 22.6945 7.12112 22.2526C7.12112 21.7666 6.65546 21.7666 6.56677 21.7666H6.32285V22.7386H6.30067ZM6.30067 24.0641H6.67764C6.85503 24.0641 7.0546 24.042 7.18765 23.9536C7.29852 23.8653 7.38721 23.7327 7.38721 23.556C7.38721 23.3351 7.25417 23.2025 7.1433 23.1363C7.03243 23.07 6.87721 23.0479 6.65546 23.0479H6.32285V24.0641H6.30067Z"
          fill="white"
        />
        <path
          d="M11.7961 23.9758C11.5965 23.6886 11.5078 23.3351 11.5078 22.9154C11.5078 22.4957 11.6187 22.1422 11.7961 21.8551C12.0178 21.5458 12.3061 21.4132 12.5278 21.4132C12.7496 21.4132 13.0378 21.5237 13.2596 21.8551C13.4591 22.1422 13.5478 22.4957 13.5478 22.9154C13.5478 23.3351 13.437 23.6886 13.2596 23.9758C13.0378 24.285 12.7496 24.4176 12.5278 24.4176C12.3061 24.4176 12.0178 24.3071 11.7961 23.9758ZM12.04 22.0539C11.8848 22.2969 11.8183 22.6282 11.8183 22.9375C11.8183 23.2468 11.8848 23.5781 12.04 23.8211C12.1952 24.0641 12.3948 24.1525 12.55 24.1525C12.7052 24.1525 12.9048 24.0641 13.06 23.8211C13.2152 23.5781 13.2818 23.2468 13.2818 22.9375C13.2818 22.6282 13.2152 22.2969 13.06 22.0539C12.9048 21.8109 12.7052 21.7225 12.55 21.7225C12.3948 21.7225 12.1952 21.8109 12.04 22.0539Z"
          fill="white"
        />
        <path
          d="M17.5168 23.9758C17.3172 23.6886 17.2285 23.3352 17.2285 22.9154C17.2285 22.4957 17.3394 22.1423 17.5168 21.8551C17.7385 21.5458 18.0268 21.4133 18.2485 21.4133C18.4703 21.4133 18.7585 21.5237 18.9803 21.8551C19.1799 22.1423 19.2685 22.4957 19.2685 22.9154C19.2685 23.3352 19.1577 23.6886 18.9803 23.9758C18.7585 24.2851 18.4703 24.4176 18.2485 24.4176C18.0268 24.4176 17.7385 24.3072 17.5168 23.9758ZM17.7607 22.0539C17.6055 22.2969 17.539 22.6283 17.539 22.9375C17.539 23.2468 17.6055 23.5782 17.7607 23.8212C17.9159 24.0642 18.1155 24.1525 18.2707 24.1525C18.4259 24.1525 18.6255 24.0642 18.7807 23.8212C18.9359 23.5782 19.0025 23.2468 19.0025 22.9375C19.0025 22.6283 18.9359 22.2969 18.7807 22.0539C18.6255 21.8109 18.4259 21.7225 18.2707 21.7225C18.1155 21.7225 17.9159 21.8109 17.7607 22.0539Z"
          fill="white"
        />
        <path
          d="M24.0578 21.7887V24.3734H23.7252V21.7887H23.0156V21.4795H24.7452V21.7887H24.0578Z"
          fill="white"
        />
        <path
          d="M8.00546 27.7975C7.91676 27.7091 7.78372 27.5986 7.6285 27.5324C7.47328 27.4661 7.29588 27.4219 7.11849 27.4219C6.45326 27.4219 5.96543 27.9521 5.96543 28.6148C5.96543 29.388 6.60848 29.7856 7.14066 29.7856C7.34023 29.7856 7.51763 29.7194 7.67285 29.6531C7.82807 29.5647 7.96111 29.4543 8.00546 29.4101V29.8077C7.69502 30.0286 7.34023 30.0949 7.14066 30.0949C6.27587 30.0949 5.63281 29.4543 5.63281 28.6148C5.63281 27.7533 6.27587 27.1126 7.14066 27.1126C7.31806 27.1126 7.65067 27.1347 8.00546 27.3998V27.7975Z"
          fill="white"
        />
        <path
          d="M13.174 29.2335H11.9322L11.5774 30.0508H11.2227L12.5531 27.0244L13.8614 30.0508H13.5066L13.174 29.2335ZM13.0409 28.9242L12.5531 27.7755L12.0653 28.9242H13.0409Z"
          fill="white"
        />
        <path
          d="M16.6719 30.0507L17.1597 27.0022L18.2684 29.4322L19.3993 27.0022L19.8428 30.0507H19.5102L19.2441 28.0404L18.2684 30.1611L17.2928 28.0404L17.0045 30.0507H16.6719Z"
          fill="white"
        />
        <path
          d="M23.6624 27.1567C23.9506 27.1567 24.1945 27.2009 24.3941 27.3555C24.6158 27.5323 24.6602 27.7973 24.6602 27.9741C24.6602 28.3717 24.3941 28.7914 23.751 28.7914H23.4628V30.0506H23.1523V27.1567H23.6624V27.1567ZM23.4628 28.4822H23.7289C24.1058 28.4822 24.3498 28.2833 24.3498 27.952C24.3498 27.4881 23.9284 27.4439 23.7067 27.4439H23.4628V28.4822Z"
          fill="white"
        />
        <path
          d="M40.5525 5C41.474 5 42.3269 5.2481 43.1114 5.74429C43.9083 6.24048 44.5495 6.99097 45.0351 7.99576C45.5208 9.00054 45.7636 10.2348 45.7636 11.6986C45.7636 13.1624 45.5083 14.3966 44.9978 15.4014C44.4997 16.4062 43.846 17.1567 43.0367 17.6529C42.2397 18.1491 41.393 18.3972 40.4965 18.3972C38.5416 18.3972 37.1346 17.6529 36.2754 16.1643L37.8817 14.8246C38.442 15.817 39.2825 16.3132 40.4031 16.3132C40.8763 16.3132 41.337 16.1705 41.7853 15.8852C42.2335 15.5999 42.6133 15.1719 42.9246 14.6013C43.2483 14.0183 43.4476 13.3112 43.5223 12.4801H38.9463V10.638H43.5036C43.3666 9.48433 43.018 8.60359 42.4576 7.99576C41.8973 7.38792 41.2623 7.084 40.5525 7.084C39.5689 7.084 38.8031 7.43754 38.2552 8.14461L36.705 6.74907C37.6513 5.58303 38.9338 5 40.5525 5Z"
          fill="#4520AB"
        />
        <path
          d="M54.3853 18.2111H52.1813V10.9915H49.9214C49.7719 12.9143 49.6287 14.3656 49.4918 15.3456C49.3424 16.4124 49.0373 17.1877 48.5766 17.6715C48.1283 18.1553 47.5182 18.3972 46.7462 18.3972C46.1859 18.3972 45.694 18.2111 45.2707 17.839L45.8123 15.9596C46.0489 16.1953 46.3228 16.3132 46.6341 16.3132C46.8458 16.3132 47.0201 16.2201 47.1571 16.0341C47.3065 15.848 47.4186 15.5255 47.4933 15.0665C47.7174 13.702 47.8855 11.649 47.9976 8.90751H54.3853V18.2111Z"
          fill="#4520AB"
        />
        <path
          d="M60.9151 11.8847C61.6124 11.8847 62.1914 12.0397 62.6521 12.3498C63.1128 12.66 63.449 13.0569 63.6607 13.5407C63.8848 14.0121 63.9969 14.5021 63.9969 15.0107C63.9969 15.5193 63.8848 16.0217 63.6607 16.5178C63.449 17.0016 63.1128 17.4048 62.6521 17.7273C62.1914 18.0498 61.6124 18.2111 60.9151 18.2111H56.1523V8.90751H58.3563V11.8847H60.9151ZM60.7283 16.1271C61.0645 16.1271 61.326 16.0093 61.5128 15.7736C61.6996 15.5379 61.793 15.2836 61.793 15.0107C61.793 14.7378 61.6996 14.4959 61.5128 14.285C61.3385 14.0741 61.077 13.9687 60.7283 13.9687H58.3563V16.1271H60.7283Z"
          fill="#4520AB"
        />
        <path
          d="M69.7423 9.16801C70.4396 9.16801 71.0871 9.34168 71.6847 9.68901C72.2824 10.0363 72.7556 10.5573 73.1042 11.252C73.4653 11.9467 73.6459 12.7902 73.6459 13.7826C73.6459 14.7874 73.4342 15.6371 73.0108 16.3318C72.5999 17.0264 72.0645 17.5474 71.4046 17.8948C70.7446 18.2297 70.0411 18.3972 69.294 18.3972C68.522 18.3972 67.806 18.2049 67.1461 17.8204C66.4986 17.4234 65.9694 16.7846 65.5585 15.9038C65.1476 15.0107 64.9422 13.8508 64.9422 12.4243C64.9422 10.8985 65.0916 9.68281 65.3904 8.77726C65.7017 7.87171 66.15 7.20185 66.7352 6.76768C67.3204 6.33351 68.0675 6.042 68.9765 5.89314L70.6948 5.61404C71.2552 5.5148 72.0023 5.31012 72.9361 5V7.084C72.1641 7.40653 71.3859 7.63602 70.6014 7.77247L68.9018 8.05158C68.3788 8.13841 67.9555 8.30588 67.6317 8.55397C67.308 8.80207 67.0589 9.16181 66.8846 9.63319C66.7103 10.1046 66.592 10.7434 66.5298 11.5497C66.7912 10.7434 67.1959 10.148 67.7438 9.76344C68.2917 9.36649 68.9578 9.16801 69.7423 9.16801ZM69.294 16.3132C69.9664 16.3132 70.4894 16.0961 70.8629 15.6619C71.2489 15.2153 71.4419 14.5889 71.4419 13.7826C71.4419 12.9887 71.2365 12.3684 70.8256 11.9219C70.4271 11.4753 69.9166 11.252 69.294 11.252C68.6216 11.252 68.0924 11.4753 67.7064 11.9219C67.3329 12.3684 67.1461 13.0135 67.1461 13.857C67.1461 14.6385 67.3453 15.2464 67.7438 15.6805C68.1547 16.1023 68.6714 16.3132 69.294 16.3132Z"
          fill="#4520AB"
        />
        <path
          d="M79.7461 8.72144C80.3438 8.72144 80.9353 8.90131 81.5205 9.26104C82.1182 9.60838 82.6162 10.148 83.0147 10.8799C83.4132 11.6118 83.6124 12.5049 83.6124 13.5593C83.6124 14.6261 83.4132 15.5255 83.0147 16.2573C82.6162 16.9768 82.1119 17.5164 81.5018 17.8762C80.9041 18.2235 80.3002 18.3972 79.6901 18.3972C78.7313 18.3972 77.9531 18.0809 77.3554 17.4482V21.9325H75.1515V14.0989C75.1515 12.3498 74.8962 10.6194 74.3857 8.90751H76.5896C76.5896 9.3913 76.6083 10.0239 76.6457 10.8054C76.9321 10.1356 77.3305 9.62078 77.841 9.26104C78.364 8.90131 78.999 8.72144 79.7461 8.72144ZM79.4099 16.3132C80.0201 16.3132 80.5057 16.0837 80.8668 15.6247C81.2279 15.1533 81.4084 14.4649 81.4084 13.5593C81.4084 12.6662 81.2092 11.9839 80.8107 11.5125C80.4247 11.0411 79.9267 10.8054 79.3165 10.8054C78.694 10.8054 78.2084 11.0659 77.8597 11.5869C77.5235 12.1079 77.3554 12.7654 77.3554 13.5593C77.3554 14.3656 77.5297 15.0293 77.8784 15.5503C78.227 16.0589 78.7375 16.3132 79.4099 16.3132Z"
          fill="#4520AB"
        />
        <path
          d="M93.1866 8.90751L89.8434 18.9182C89.1336 21.0518 87.932 22.1186 86.2386 22.1186C85.0931 22.1186 84.1779 21.7217 83.493 20.9277L83.9413 18.9182C84.3397 19.2531 84.7008 19.5012 85.0246 19.6625C85.3483 19.8361 85.6783 19.923 86.0145 19.923C86.4254 19.923 86.7616 19.7927 87.0231 19.5322C87.297 19.2841 87.5025 18.9616 87.6394 18.5646L87.7515 18.2111L83.9039 8.90751H86.332L88.7974 15.5689L90.7959 8.90751H93.1866Z"
          fill="#4520AB"
        />
        <path
          d="M101.958 16.1643C101.373 16.9458 100.769 17.5164 100.146 17.8762C99.5359 18.2235 98.8013 18.3972 97.9421 18.3972C97.1701 18.3972 96.4292 18.2235 95.7195 17.8762C95.0222 17.5164 94.4494 16.9768 94.0012 16.2573C93.5529 15.5255 93.3288 14.6261 93.3288 13.5593C93.3288 12.4801 93.5342 11.5807 93.9451 10.8613C94.3685 10.1294 94.9101 9.58977 95.5701 9.24244C96.2425 8.8951 96.9584 8.72144 97.718 8.72144C98.5522 8.72144 99.262 8.8765 99.8472 9.18662C100.432 9.48433 101.024 9.98052 101.622 10.6752L99.978 11.9591C99.6418 11.5621 99.3118 11.2706 98.9881 11.0845C98.6768 10.8985 98.2845 10.8054 97.8114 10.8054C97.1141 10.8054 96.56 11.0411 96.1491 11.5125C95.7382 11.9715 95.5327 12.6538 95.5327 13.5593C95.5327 14.44 95.7693 15.1223 96.2425 15.6061C96.7281 16.0775 97.332 16.3132 98.0542 16.3132C98.49 16.3132 98.876 16.2077 99.2122 15.9968C99.5608 15.786 99.9157 15.4386 100.277 14.9548L101.958 16.1643Z"
          fill="#4520AB"
        />
        <path
          d="M41.9347 26.0494C42.632 26.0494 43.2795 26.2231 43.8771 26.5704C44.4748 26.9177 44.948 27.4387 45.2966 28.1334C45.6577 28.8281 45.8383 29.6716 45.8383 30.664C45.8383 31.6688 45.6266 32.5185 45.2032 33.2132C44.7923 33.9078 44.2569 34.4288 43.597 34.7762C42.937 35.1111 42.2335 35.2786 41.4864 35.2786C40.7144 35.2786 39.9984 35.0863 39.3385 34.7017C38.691 34.3048 38.1618 33.6659 37.7509 32.7852C37.34 31.8921 37.1346 30.7322 37.1346 29.3057C37.1346 27.7799 37.284 26.5642 37.5828 25.6586C37.8941 24.7531 38.3424 24.0832 38.9276 23.6491C39.5128 23.2149 40.2599 22.9234 41.1689 22.7745L42.8872 22.4954C43.4476 22.3962 44.1947 22.1915 45.1285 21.8814V23.9654C44.3565 24.2879 43.5783 24.5174 42.7938 24.6539L41.0942 24.933C40.5712 25.0198 40.1479 25.1873 39.8241 25.4354C39.5004 25.6835 39.2513 26.0432 39.077 26.5146C38.9027 26.986 38.7844 27.6248 38.7221 28.4311C38.9836 27.6248 39.3883 27.0294 39.9362 26.6448C40.4841 26.2479 41.1502 26.0494 41.9347 26.0494ZM41.4864 33.1946C42.1588 33.1946 42.6818 32.9775 43.0553 32.5433C43.4413 32.0967 43.6343 31.4703 43.6343 30.664C43.6343 29.8701 43.4289 29.2498 43.018 28.8033C42.6195 28.3567 42.109 28.1334 41.4864 28.1334C40.814 28.1334 40.2848 28.3567 39.8988 28.8033C39.5253 29.2498 39.3385 29.8949 39.3385 30.7384C39.3385 31.5199 39.5377 32.1277 39.9362 32.5619C40.3471 32.9837 40.8638 33.1946 41.4864 33.1946Z"
          fill="#4520AB"
        />
        <path
          d="M55.3005 25.7889L51.9572 35.7996C51.2475 37.9332 50.0459 39 48.3524 39C47.2069 39 46.2917 38.603 45.6069 37.8091L46.0551 35.7996C46.4536 36.1345 46.8147 36.3826 47.1384 36.5438C47.4621 36.7175 47.7921 36.8043 48.1283 36.8043C48.5392 36.8043 48.8754 36.6741 49.1369 36.4136C49.4108 36.1655 49.6163 35.843 49.7533 35.446L49.8653 35.0925L46.0178 25.7889H48.4458L50.9113 32.4503L52.9098 25.7889H55.3005Z"
          fill="#4520AB"
        />
        <path
          d="M65.4351 27.8729H61.9611V35.0925H59.7571V27.8729H56.2831V25.7889H65.4351V27.8729Z"
          fill="#4520AB"
        />
        <path
          d="M70.0972 31.3897H69.0325V35.0925H66.8286V25.7889H69.0325V29.3057H69.9664L72.656 25.7889H75.0654L71.7781 30.2174L75.2521 35.0925H72.6747L70.0972 31.3897Z"
          fill="#4520AB"
        />
        <path
          d="M84.1353 30.3663C84.1353 30.6392 84.1042 30.9493 84.042 31.2966L77.4675 31.3524C77.6169 31.9603 77.9157 32.4193 78.364 32.7294C78.8123 33.0395 79.3726 33.1946 80.045 33.1946C80.543 33.1946 80.9851 33.1325 81.3711 33.0085C81.7695 32.872 82.1804 32.6363 82.6038 32.3014L83.8925 33.883C83.3945 34.342 82.8466 34.6893 82.2489 34.925C81.6512 35.1607 80.9602 35.2786 80.1757 35.2786C79.2916 35.2786 78.4761 35.1049 77.729 34.7576C76.9943 34.4102 76.4029 33.8768 75.9546 33.1573C75.5063 32.4255 75.2822 31.5199 75.2822 30.4407C75.2822 29.3615 75.4877 28.4621 75.8986 27.7427C76.3095 27.0108 76.8449 26.4712 77.5048 26.1238C78.1648 25.7765 78.8807 25.6028 79.6527 25.6028C81.0598 25.6028 82.1555 25.9936 82.94 26.7751C83.7369 27.5442 84.1353 28.7412 84.1353 30.3663ZM79.7835 27.6868C79.1734 27.6868 78.6628 27.8419 78.2519 28.152C77.8535 28.4497 77.592 28.9025 77.4675 29.5103H81.9314C81.894 29.0017 81.6948 28.5738 81.3337 28.2264C80.9851 27.8667 80.4683 27.6868 79.7835 27.6868Z"
          fill="#4520AB"
        />
        <path
          d="M94.438 35.0925H92.2341V28.3567L90.8146 32.2456H89.1336L87.7141 28.3567V35.0925H85.5102V25.7889H88.6107L89.9741 29.9569L91.3376 25.7889H94.438V35.0925Z"
          fill="#4520AB"
        />
        <path
          d="M103.732 35.0925H101.528V27.8729H98.2036V35.0925H95.9997V25.7889H103.732V35.0925Z"
          fill="#4520AB"
        />
      </svg>
    </SvgIcon>
  );
}
