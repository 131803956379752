import { Components, Theme } from '@mui/material';

export const muiMenuItemTheme: Components<Theme> = {
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        '.MuiTypography-root': {
          color: theme.palette.kit.text.main,
          textDecoration: 'none',
        },
        '&.Mui-focusVisible': {
          background: 'none',
          '.MuiTypography-root': {
            color: theme.palette.kit.text.h,
            paddingBottom: 4,
            borderBottom: `solid 1px ${theme.palette.kit.stroke.gray}`,
          },
        },
        '&:hover': {
          background: 'none',
          color: theme.palette.kit.text.h,
          '.MuiTypography-root': {
            color: theme.palette.kit.text.main,
            paddingBottom: 4,
            borderBottom: `solid 1px ${theme.palette.kit.stroke.turquoise}`,
          },
        },
        background: 'none',
        color: theme.palette.kit.text.main,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 0,
        paddingTop: 12,
        marginBottom: 8,
      }),
    },
  },
};
