declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const YM_ACCOUNT_ID = 89515044;
export const YM_REACH_GOAL = 'reachGoal';

export const sendTrackerEvent = (EVENT_TYPE: string) => {
  if (window && window.ym) {
    console.info(`[YM] ${YM_ACCOUNT_ID} -> ${YM_REACH_GOAL} -> ${EVENT_TYPE}`);

    window.ym(YM_ACCOUNT_ID, YM_REACH_GOAL, EVENT_TYPE);
  }

  if (window && window.dataLayer) {
    console.info(`[GTM] Event -> ${EVENT_TYPE}`);

    window.dataLayer.push({ event: EVENT_TYPE });
  }
};

export const sendUserId = (userId: string | number) => {
  if (window && window.dataLayer) {
    console.info(`[GTM] UserId -> ${userId}`);

    window.dataLayer.push({ user_id: userId });
  }
};
