const sizes = {
  default: {
    xs: '40px',
    xl: '64px',
  },
  small: {
    xs: '32px',
    xl: '60px',
  },
};

const bgColorTheme = {
  default: 'kit.background.white',
  light: 'kit.background.gray',
  medium: 'kit.background.darkgray',
  dark: 'kit.background.purple',
  lightPurple: 'kit.background.lightpurple',
  middlePurple: 'kit.background.middlepurple',
};

const colorTheme = {
  dark: 'kit.text.mainInverse',
};

export const createBgTheme = (theme, size) => ({
  paddingBottom: sizes[size],
  backgroundColor: bgColorTheme[theme],
  color: colorTheme[theme] || 'kit.text.secondary',
});

export const createSubtitleColorTheme = (theme) => ({
  color: colorTheme[theme] || 'kit.text.secondary',
  opacity: colorTheme[theme] ? 0.4 : 1,
});
export const createTitleColorTheme = (theme) => ({
  color: colorTheme[theme] || 'kit.text.main',
});

const styles = {
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'kit.background.lightpurple',
  },

  container: {
    position: 'relative',
    padding: {
      xs: '0 16px',
      lg: '0 60px',
    },
  },
  title: {
    whiteSpace: 'pre-line',
    fontSize: { xs: 22, lg: 34 },
    marginBottom: {
      xs: '24px',
      xl: '32px',
    },
  },
  imageBlock: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  bg: {
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: '0',
  },
};

export default styles;
