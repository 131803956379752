import { Components, Theme } from '@mui/material';

export const muiDialogTheme: Components<Theme> = {
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        '& .MuiDialog-container': {
          '.MuiDialogTitle-root': {
            padding: 0,
          },
          '.MuiDialogContent-root': {
            paddingLeft: 0,
            paddingRight: 0,
          },
          '.MuiDialogActions-root': {
            padding: 0,
          },
          '& .MuiPaper-root.MuiDialog-paper': {
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(0, 2),
            },
            [theme.breakpoints.up('sm')]: {
              padding: theme.spacing(4, 6),
            },
          },
        },
      }),
    },
  },
};
