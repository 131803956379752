import { Components, Theme } from '@mui/material';

export const muiTabsTheme: Components<Theme> = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        minHeight: 40,
        height: 40,
        '.MuiTabs-scroller': {
          '.MuiTabs-indicator': {
            background: theme.palette.kit.text.h,
            height: 1,
          },
        },
      }),
    },
  },
};
