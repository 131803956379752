import { Components, Theme } from '@mui/material';

export const muiLinearProgressTheme: Components<Theme> = {
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        width: 256,
        height: 4,
        borderRadius: 10,
        background: 'rgba(196, 196, 196, 0.3)',
        '.MuiLinearProgress-bar': {
          background: 'linear-gradient(270deg,  #29EDFF 0%, #956FFF 100%);',
        },
      },
    },
  },
};
