import { Components, Theme } from '@mui/material';

export const muiListItemTheme: Components<Theme> = {
  MuiListItem: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        paddingLeft: '16px !important',
        '& .MuiListItemIcon-root': {
          minWidth: 26,
          color: theme.palette.kit.main,
        },
      }),
    },
  },
};
