import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

window.addEventListener('error', function (event) {
  if (/ResizeObserver loop limit exceeded/.test(event.message)) {
    event.preventDefault();
    event.stopPropagation();
    console.warn('ResizeObserver loop limit exceeded error was suppressed.');
  }
});

root.render(
  <Router>
    <App />
  </Router>,
);
