import { Components, Theme } from '@mui/material';

export const muiSelectTheme: Components<Theme> = {
  MuiSelect: {
    defaultProps: {},
    styleOverrides: {
      standard: {},
    },
  },
};
