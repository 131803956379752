import Layout from '../components/Layout/Layout';
import MainBlock from '../components/MainBlock/MainBlock';

function IndexPage() {
  return (
    <Layout>
      <MainBlock />
    </Layout>
  );
}

export default IndexPage;
