import { Theme } from '@mui/material';

const styles = {
  mainItemsContainer: (theme: Theme) => ({
    marginTop: '28px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '8px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  }),
  balanceTitle: {
    color: 'kit.text.secondary',
  },
  headerBlock: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '4px',
    width: '100%',
    color: 'kit.text.main',
    '& div': {
      flexBasis: '25%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '4px',
    width: '100%',
    height: { xs: 300, sm: 280, lg: 232 },
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
    '&::-webkit-scrollbar': {
      width: '0.1em',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
    },
  },
};

export default styles;
