import { SvgIcon, SvgIconProps } from '@mui/material';

export function YouTubeOutlinedIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        d="M0.5 16C0.5 7.43959 7.43959 0.5 16 0.5H24C32.5604 0.5 39.5 7.43959 39.5 16V24C39.5 32.5604 32.5604 39.5 24 39.5H16C7.43959 39.5 0.5 32.5604 0.5 24V16Z"
        stroke="white"
      />
      <path
        d="M29.3301 18.4872C29.3301 18.0924 29.2622 17.6318 29.2622 17.1054C29.1942 16.579 29.1263 16.0526 29.0584 15.592C28.9225 15.0656 28.7187 14.6707 28.3112 14.3417C27.9715 14.0127 27.496 13.8153 27.0205 13.7495C25.4582 13.5521 23.1486 13.4863 20.0239 13.4863C16.8992 13.4863 14.5217 13.5521 13.0273 13.7495C12.5518 13.8153 12.0763 14.0127 11.7367 14.3417C11.3971 14.6707 11.1253 15.0656 10.9895 15.592C10.8536 16.0526 10.7857 16.5132 10.7857 17.1054C10.7178 17.6318 10.7178 18.0924 10.7178 18.4872C10.7178 18.882 10.7178 19.3426 10.7178 20.0006C10.7178 20.6586 10.7178 21.1851 10.7178 21.5141C10.7178 21.9089 10.7857 22.3695 10.7857 22.8959C10.8536 23.4223 10.9216 23.9487 10.9895 24.4093C11.1253 24.9357 11.3291 25.3305 11.7367 25.6595C12.0763 25.9885 12.5518 26.1859 13.0273 26.2517C14.5897 26.4491 16.8992 26.5149 20.0239 26.5149C23.1486 26.5149 25.5261 26.4491 27.0205 26.2517C27.496 26.1859 27.9715 25.9885 28.3112 25.6595C28.6508 25.3305 28.9225 24.9357 29.0584 24.4093C29.1942 23.9487 29.2622 23.4881 29.2622 22.8959C29.3301 22.3695 29.3301 21.9089 29.3301 21.5141C29.3301 21.1192 29.3301 20.6586 29.3301 20.0006C29.3301 19.3426 29.3301 18.8162 29.3301 18.4872ZM23.6241 20.527L18.4729 23.4494C18.4729 23.4494 18.0452 23.7513 17.9093 23.7513C17.8259 23.7513 17.7206 23.8171 17.5848 23.7513C17.381 23.6197 17.3096 23.3294 17.3096 23.0662L17.3714 17.1054C17.3714 16.8422 17.3747 16.25 17.5785 16.1184C17.7823 15.9868 18.054 15.9868 18.2578 16.1184L23.6241 19.3426C23.8279 19.4742 23.9638 19.6716 23.9638 19.869C23.9638 20.0664 23.8279 20.3954 23.6241 20.527Z"
        stroke="white"
      />
    </SvgIcon>
  );
}
