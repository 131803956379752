import { Components, Theme } from '@mui/material';

export const muiRadioGroupTheme: Components<Theme> = {
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        color: theme.palette.kit.stroke.purple,
        '&.Mui-checked': {
          color: theme.palette.kit.stroke.purple,
        },
      }),
    },
  },
};
