import { Components, Theme } from '@mui/material';

export const muiCheckboxTheme: Components<Theme> = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.MuiCheckbox-root.Mui-checked': {
          width: 42,
          height: 42,
        },
      },
    },
  },
};
