import React, { useState } from 'react';
import { format, parse } from 'date-fns';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import desktopBg from './backgrounds/desktop.png';
import desktopLgBg from './backgrounds/desktop-large.png';
import mobileBg from './backgrounds/mobile.png';
import Section from '../Section/Section';
import Link from '../../kit/Link/Link';
import BalanceHistory from '../BalanceHistory/BalanceHistory';
import Balance from '../Balance/Balance';
import { useAppSelector } from '../../redux/hooks';
import { Item } from '../BalanceItem/BalanceItem';

const TAB_NAMES = { balance: 'Баланс', history: 'История' };
const BalanceTab = ({ totalAmount }) => (
  <>
    <Balance points={totalAmount} />
    <Typography
      variant="desktopP2"
      color={'kit.text.secondary'}
      sx={{ mt: 4, mb: 1.5, fontSize: { xs: 14, lg: 16 } }}
    >
      Для того, чтобы воспользоваться баллами:
    </Typography>
    <Link
      to={'https://t.me/khsevara'}
      target="_blank"
      variant="kitPrimary"
      sx={{ fontSize: { xs: 14, lg: 16 } }}
    >
      напишите менеджеру Эльбруса
    </Link>
  </>
);

const TabComponent = ({ selectedTab, handleTabChange }) => {
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        width: 'fit-content',
        mt: 2,
        marginBottom: '24px',
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="Выбор типа событий"
      >
        {Object.keys(TAB_NAMES).map((tabName) => (
          <Tab key={tabName} label={TAB_NAMES[tabName]} value={tabName} />
        ))}
      </Tabs>
    </Box>
  );
};

const BackgroundBox = ({ backgroundImage }) => (
  <Box
    sx={{
      display: { xs: 'none', lg: 'block' },
      position: 'absolute',
      bottom: 0,
      backgroundImage: backgroundImage,
      backgroundPosition: 'bottom right',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      width: '100%',
      zIndex: 0,
    }}
  />
);
const parseDate = (dateString) =>
  parse(dateString, 'dd.MM.yyyy HH:mm', new Date());

export default function MainBlock() {
  const {
    user: { transactions },
  } = useAppSelector((state) => state);
  const preparedTransactions: Item[] = transactions
    ?.filter((item) => new Date(item.date).getTime() < Date.now())
    .map((item) => {
      const { amount, date: rawDate, comment, type } = item;
      let title;
      const date = format(new Date(rawDate), 'dd.MM.yyyy HH:mm');
      const items: Item[] = [];
      if (type === 'income') {
        title = 'Начисление баллов';
        items.push({ amount, date, comment, title, type });
      } else if (type === 'outcome') {
        title = 'Списание баллов';
        items.push({ amount, date, comment, title, type });
      }
      return items;
    })
    .flat()
    .sort((a, b) => parseDate(a.date).getTime() - parseDate(b.date).getTime());

  const totalAmount = preparedTransactions?.reduce(
    (acc, item) => acc + (item.type === 'income' ? item.amount : -item.amount),
    0,
  );
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isXxlUp = useMediaQuery(theme.breakpoints.up('xxl'));
  const [selectedTab, setSelectedTab] = useState<'balance' | 'history'>(
    'balance',
  );

  let backgroundImage;

  if (isXxlUp) {
    backgroundImage = `url(${desktopLgBg})`;
  } else if (isLgUp) {
    backgroundImage = `url(${desktopBg})`;
  } else {
    backgroundImage = `url(${mobileBg})`;
  }
  const handleTabChange = () => {
    setSelectedTab((prev) => (prev === 'history' ? 'balance' : 'history'));
  };
  return (
    <Section
      title={'Программа лояльности'}
      titleSx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: 'space-between',
      }}
    >
      {selectedTab === 'balance' && (
        <BackgroundBox backgroundImage={backgroundImage} />
      )}
      <Box
        sx={{
          width: '100%',
          height: '444px',
          position: 'relative',
        }}
      >
        <TabComponent
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
        {selectedTab === 'history' ? (
          <BalanceHistory items={preparedTransactions} />
        ) : (
          <BalanceTab totalAmount={totalAmount} />
        )}
      </Box>
    </Section>
  );
}
