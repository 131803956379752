import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import Link from '../../kit/Link/Link';
import { ErrorField } from '../MainModal/MainModal';
import { LoginModalState } from '../../redux/interfaces/userState.interface';

interface ResetPasswordProps {
  isDone: boolean;
  emailValue: string;
  emailError: ErrorField;
  loginModalState: LoginModalState;
  canRequestPasswordReset: boolean;
  handleRequestPasswordLink: any;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
  setField: (field: string, value: string) => void;
  toggleView: (view: string) => void;
}
export default function RequestPasswordModal(props: ResetPasswordProps) {
  const {
    emailValue,
    emailError,
    loginModalState,
    canRequestPasswordReset,
    handleRequestPasswordLink,
    onBlurHandler,
    setField,
    isDone,
    toggleView,
  } = props;
  return (
    <>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          {!isDone && (
            <>
              <Typography
                sx={{ letterSpacing: -2, color: 'kit.text.main' }}
                variant="desktopH3"
              >
                Сбросить пароль
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 1,
                  }}
                >
                  <Typography
                    sx={(theme) => ({
                      letterSpacing: -0.5,
                      color: 'kit.text.h',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 14,
                      },
                      cursor: 'pointer',
                    })}
                    onClick={() => toggleView('LOGIN')}
                  >
                    Войти
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      letterSpacing: -0.5,
                      color: 'kit.text.h',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 14,
                      },
                      mx: 1,
                    })}
                  >
                    /
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      letterSpacing: -0.5,
                      color: 'kit.text.h',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 14,
                      },
                      cursor: 'pointer',
                    })}
                    onClick={() => toggleView('REGISTER')}
                  >
                    Регистрация
                  </Typography>
                </Box>
                <Box sx={{ mx: 2, mt: 2 }}>
                  <Typography
                    variant="desktopP1"
                    sx={(theme) => ({
                      letterSpacing: -0.5,
                      color: 'kit.text.main',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 16,
                      },
                      textAlign: 'center',
                    })}
                  >
                    Введите адрес вашей почты, мы пришлём ссылку для создания
                    нового пароля
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {isDone && (
            <>
              <Typography
                sx={{ letterSpacing: '-0.07em', color: 'kit.text.main' }}
                variant="desktopH2"
                textAlign="center"
              >
                Письмо отправлено на вашу почту!
              </Typography>
            </>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'}>
          {!isDone && (
            <TextField
              variant="standard"
              sx={{ my: 1 }}
              id="email"
              label="Email"
              error={true}
              type={'email'}
              placeholder="test@example.com"
              value={emailValue}
              fullWidth
              onChange={(e) => {
                setField(e.target.id, e.target.value);
              }}
              onBlur={onBlurHandler}
              helperText={emailError.isError && emailError.message}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box
          sx={{
            py: 1,
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {loginModalState.isLoading && <CircularProgress />}
          {!isDone && (
            <Button
              disabled={!canRequestPasswordReset}
              sx={{ width: 197, height: 41 }}
              variant="kitPrimary"
              onClick={handleRequestPasswordLink}
            >
              Сбросить пароль
            </Button>
          )}
          {isDone && (
            <>
              <Button
                variant="kitPrimary"
                sx={{
                  mb: 4,
                }}
                onClick={() => {
                  toggleView('LOGIN');
                }}
              >
                Хорошо
              </Button>
              <Typography
                variant="mobileP3"
                sx={(theme) => ({
                  color: 'kit.text.main',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                  },
                  textAlign: 'center',
                })}
              >
                Продолжая, вы принимаете условия{' '}
                <Link to="https://elbrusboot.camp/privacy-policy/">
                  Соглашения
                </Link>
              </Typography>
            </>
          )}
        </Box>
      </DialogActions>
    </>
  );
}
