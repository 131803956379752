import { useCallback, useEffect, useState } from 'react';

import { Dialog } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  requestPasswordReset,
  resetLoginError,
  resetPassword,
  userLogin,
  userRegister,
} from '../../redux/reducers/userSlice';
import { sendTrackerEvent } from '../../helpers/trackers';
import LoginModal from '../LoginModal/LoginModal';
import RegisterModal from '../RegisterModal/RegisterModal';
import ResetPasswordModal from '../ResetPasswordModal/ResetPasswordModal';
import RequestPasswordModal from '../RequestPasswordModal/RequestPasswordModal';
import { useNavigate } from 'react-router-dom';

const fieldNames = {
  phone: 'Телефон',
  email: 'Email',
};

export type ModalViewType =
  | 'LOGIN'
  | 'REGISTER'
  | 'REQUEST_PASSWORD_LINK'
  | 'REQUEST_PASSWORD_LINK_DONE'
  | 'RESET_PASSWORD';

export type ErrorFieldsState = {
  emailError: { isError: boolean; message: string };
  passwordError?: { isError: boolean; message: string };
  nameError?: { isError: boolean; message: string };
  surnameError?: { isError: boolean; message: string };
};
export type ErrorField = ErrorFieldsState[keyof ErrorFieldsState];

export default function MainModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state);
  const { isLoading } = user;

  const loginModalState = user.loginModal;
  const { error: responseError } = loginModalState || {};
  const { error: errorField, statusCode } = responseError || {};

  const searchParams = new URLSearchParams(location.search);
  const query = Object.fromEntries(searchParams.entries());
  const isReady = location.pathname !== '' && location.search !== '';
  const replace = (path) => {
    navigate(path, { replace: true });
  };
  const [modalView, setModalView] = useState<ModalViewType>('LOGIN');

  useEffect(() => {
    if (isReady && query.resetPasswordToken) {
      setModalView('RESET_PASSWORD');
    }
  }, [isReady, query]);

  const [modalFieldsState, setModalFieldsState] = useState({
    emailValue: '',
    passwordValue: '',
    nameValue: '',
    surnameValue: '',
  });
  const fieldsRequirements = {
    emailError: /^[A-Za-z0-9-._+]{1,}@[A-Za-z0-9-_]{1,}\.[A-Za-z0-9-_]{1,}$/,
    passwordError: /(?=.*[0-9])(?=.*[A-Za-z])[0-9a-zA-Zа-яА-я !@#$%^&*]{6,}/,
    nameError: /^[A-Za-z- А-Яа-яЁё]{0,39}[A-Za-zА-Яа-яЁё]$/,
    surnameError: /^[A-Za-z- А-Яа-яЁё]{0,39}[A-Za-zА-Яа-яЁё]$/,
  };

  const initRegisterModalFieldsErrorState = {
    emailError: { isError: false, message: 'Некорректный формат email' },
    passwordError: {
      isError: false,
      message:
        'Пароль должен быть от 6 символов и содержать буквы латинского алфавита и цифры',
    },
    nameError: {
      isError: false,
      message: 'В имени должно быть от 1 до 40 букв',
    },
    surnameError: {
      isError: false,
      message: 'В фамилии должно быть от 1 до 40 букв',
    },
  };

  const initLoginModalFieldsErrorState = {
    emailError: { isError: false, message: 'Неверный логин или пароль' },
    passwordError: {
      isError: false,
      message: 'Неверный логин или пароль',
    },
  };
  const initResetModalFieldsErrorState = {
    passwordError: {
      isError: false,
      message:
        'Пароль должен быть от 6 символов и содержать буквы латинского алфавита и цифры',
    },
  };
  const initRequestModalFieldsErrorState = {
    emailError: { isError: false, message: 'Некорректный формат email' },
  };
  const initModalFieldsErrorState = {
    LOGIN: initLoginModalFieldsErrorState,
    REGISTER: initRegisterModalFieldsErrorState,
    RESET_PASSWORD: initResetModalFieldsErrorState,
    REQUEST_PASSWORD_LINK: initRequestModalFieldsErrorState,
    REQUEST_PASSWORD_LINK_DONE: {},
  };
  const [modalFieldsErrorState, setModalFieldsErrorState] =
    useState<ErrorFieldsState>(initLoginModalFieldsErrorState);

  const setField = useCallback(
    (fieldName, value) => {
      dispatch(resetLoginError());
      if (modalView === 'LOGIN') {
        setModalFieldsErrorState(initModalFieldsErrorState[modalView]);
      } else {
        setModalFieldsErrorState((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: {
            isError: false,
            message:
              initModalFieldsErrorState[modalView][`${fieldName}Error`].message,
          },
        }));
      }
      setModalFieldsState((prevState) => ({
        ...prevState,
        [`${fieldName}Value`]: value,
      }));
    },
    [dispatch, modalView, initModalFieldsErrorState],
  );
  const { emailValue, passwordValue, nameValue, surnameValue } =
    modalFieldsState;
  const { emailError, passwordError, nameError, surnameError } =
    modalFieldsErrorState;
  const [agree, setAgree] = useState(false);
  const canRegister = agree && emailValue && passwordValue.length > 5;
  const canRequestPasswordReset = !emailError.isError && !!emailValue;
  const canResetPassword =
    passwordValue && passwordValue.length > 5 && !passwordError.isError;

  useEffect(() => {
    let message;
    if (statusCode === 401) {
      message = 'Неверный логин или пароль';

      setModalFieldsErrorState((prevState) => ({
        ...prevState,
        [`emailError`]: { isError: true, message },
        [`passwordError`]: { isError: true, message },
      }));
    } else if (statusCode === 409) {
      message = `${fieldNames[errorField]} уже используется`;
      setModalFieldsErrorState((prevState) => ({
        ...prevState,
        [`${errorField}Error`]: {
          isError: true,
          message,
        },
      }));
    } else if (+statusCode === +statusCode && +statusCode > 400) {
      message = 'Нe удалось зарегистрироваться';
      setModalFieldsErrorState({
        ...initRegisterModalFieldsErrorState,
        emailError: { isError: true, message },
      });
    }
  }, [errorField, statusCode]);

  const onBlurHandler = (e) => {
    const value = e.target.value.trim();
    setField(e.target.id, value);
    setModalFieldsErrorState((prevState) => ({
      ...prevState,
      [`${e.target.id}Error`]: {
        isError: !fieldsRequirements[`${e.target.id}Error`].test(value),
        message: prevState[`${e.target.id}Error`].message,
      },
    }));
  };
  const checkErrors = (state) => {
    return Object.keys(state).filter((key) => state[key].isError).length;
  };
  const handleLogin = () => {
    sendTrackerEvent('enter_button');
    dispatch(
      userLogin({
        email: emailValue,
        password: passwordValue,
      }),
    );
  };

  const handleRegister = () => {
    if (!checkErrors(modalFieldsErrorState)) {
      sendTrackerEvent('registration');
      dispatch(
        userRegister({
          firstName: nameValue,
          lastName: surnameValue,
          email: emailValue,
          password: passwordValue,
        }),
      );
    }
  };

  const handleRequestPasswordLink = () => {
    setModalView('REQUEST_PASSWORD_LINK_DONE');
    dispatch(requestPasswordReset(emailValue));
  };

  const handleResetPassword = () => {
    toggleView('LOGIN');
    dispatch(
      resetPassword({
        code: query.resetPasswordToken as string,
        password: passwordValue,
      }),
    ),
      replace('/');
  };
  useEffect(() => {
    setModalFieldsState((prevState) => ({
      ...prevState,
      passwordValue: '',
      emailValue: loginModalState.email,
    }));
  }, [loginModalState.email]);

  const toggleView = (modalName) => {
    setAgree(false);
    dispatch(resetLoginError());
    if (modalName === 'REGISTER') {
      sendTrackerEvent('to_registration');
    }
    setModalFieldsState((prevState) => ({
      ...prevState,
      passwordValue: '',
      emailValue: '',
    }));
    setModalView(modalName);
    setModalFieldsErrorState(initModalFieldsErrorState[modalName]);
  };
  const checkBoxHandler = (e) => {
    setModalFieldsErrorState(
      Object.keys(fieldsRequirements).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            isError: !fieldsRequirements[key].test(
              modalFieldsState[key.replace(/Error$/, 'Value')] || '',
            ),
            message: modalFieldsErrorState[key]?.message,
          },
        }),
        {} as ErrorFieldsState,
      ),
    );
    setAgree(e.target.checked);
  };
  return (
    <Dialog open={!user.id && !isLoading}>
      {modalView === 'LOGIN' && (
        <LoginModal
          emailValue={emailValue}
          emailError={emailError}
          passwordValue={passwordValue}
          passwordError={passwordError}
          setField={setField}
          onBlurHandler={onBlurHandler}
          setModalView={setModalView}
          isLoading={loginModalState.isLoading}
          handleLogin={handleLogin}
          setModalFieldsErrorState={setModalFieldsErrorState}
          setModalFieldsState={setModalFieldsState}
          initRegisterModalFieldsErrorState={initRegisterModalFieldsErrorState}
          toggleView={toggleView}
        />
      )}
      {modalView === 'REGISTER' && (
        <RegisterModal
          emailValue={emailValue}
          nameValue={nameValue}
          surnameValue={surnameValue}
          nameError={nameError}
          surnameError={surnameError}
          setField={setField}
          onBlurHandler={onBlurHandler}
          emailError={emailError}
          passwordValue={passwordValue}
          passwordError={passwordError}
          toggleView={toggleView}
          loginModalState={loginModalState}
          handleRegister={handleRegister}
          canRegister={canRegister}
          setAgree={checkBoxHandler}
        />
      )}
      {modalView === 'RESET_PASSWORD' && (
        <ResetPasswordModal
          passwordValue={passwordValue}
          passwordError={passwordError}
          loginModalState={loginModalState}
          canResetPassword={canResetPassword}
          handleResetPassword={handleResetPassword}
          onBlurHandler={onBlurHandler}
          setField={setField}
        />
      )}

      {modalView.startsWith('REQUEST_PASSWORD_LINK') && (
        <RequestPasswordModal
          emailValue={emailValue}
          emailError={emailError}
          loginModalState={loginModalState}
          canRequestPasswordReset={canRequestPasswordReset}
          handleRequestPasswordLink={handleRequestPasswordLink}
          onBlurHandler={onBlurHandler}
          setField={setField}
          isDone={modalView.endsWith('DONE')}
          toggleView={toggleView}
        />
      )}
    </Dialog>
  );
}
