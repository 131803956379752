import { Components, Theme } from '@mui/material';

export const muiAccordionDetailsTheme: Components<Theme> = {
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.kit.background.lightpurple,
        padding: 0,
      }),
    },
  },
};
