import { Components, Theme } from '@mui/material';
import React from 'react';

declare module '@mui/material/Typography' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyPropsVariantOverrides {
    desktopH0: true;
    desktopH1: true;
    desktopH2: true;
    desktopH3: true;
    desktopH4: true;
    desktopH5: true;
    desktopP1: true;
    desktopP2: true;
    desktopP3: true;
    desktopP3Helper: true;
    mobileH1: true;
    mobileH2: true;
    mobileH3: true;
    mobileH4: true;
    mobileH5: true;
    mobileP1: true;
    mobileP2: true;
    mobileP3: true;
    mobileHelper: true;
    kitPrimary: true;
    kitPrimaryInverse: true;
    kitSecondary: true;
    kitSecondaryInverse: true;
    kitSecondaryInverseWhite: true;
  }
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariants {
    desktopH0: React.CSSProperties;
    desktopH1: React.CSSProperties;
    desktopH2: React.CSSProperties;
    desktopH3: React.CSSProperties;
    desktopH4: React.CSSProperties;
    desktopH5: React.CSSProperties;
    desktopP1: React.CSSProperties;
    desktopP2: React.CSSProperties;
    desktopP3: React.CSSProperties;
    desktopP3Helper: React.CSSProperties;
    mobileH1: React.CSSProperties;
    mobileH2: React.CSSProperties;
    mobileH3: React.CSSProperties;
    mobileH4: React.CSSProperties;
    mobileH5: React.CSSProperties;
    mobileP1: React.CSSProperties;
    mobileP2: React.CSSProperties;
    mobileP3: React.CSSProperties;
    mobileHelper: React.CSSProperties;
  }

  // eslint-disable-next-line no-unused-vars
  interface TypographyVariantsOptions {
    desktopH0: React.CSSProperties;
    desktopH1: React.CSSProperties;
    desktopH2: React.CSSProperties;
    desktopH3: React.CSSProperties;
    desktopH4: React.CSSProperties;
    desktopH5: React.CSSProperties;
    desktopP1: React.CSSProperties;
    desktopP2: React.CSSProperties;
    desktopP3: React.CSSProperties;
    desktopP3Helper: React.CSSProperties;
    mobileH1: React.CSSProperties;
    mobileH2: React.CSSProperties;
    mobileH3: React.CSSProperties;
    mobileH4: React.CSSProperties;
    mobileH5: React.CSSProperties;
    mobileP1: React.CSSProperties;
    mobileP2: React.CSSProperties;
    mobileP3: React.CSSProperties;
    mobileHelper: React.CSSProperties;
  }
}

export const muiTypographyTheme: Components<Theme> = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        desktopH1: 'h1',
        desktopH2: 'h2',
        desktopH3: 'h3',
        desktopH4: 'h4',
        desktopH5: 'h5',
        desktopP1: 'p',
        desktopP2: 'p',
        desktopP3: 'p',
        desktopP3Helper: 'p',
        mobileH1: 'h1',
        mobileH2: 'h2',
        mobileH3: 'h3',
        mobileH4: 'h4',
        mobileH5: 'h5',
        mobileP1: 'p',
        mobileP2: 'p',
        mobileP3: 'p',
        mobileHelper: 'p',
      },
    },
  },
};
