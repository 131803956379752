import { Cookies } from 'react-cookie';

const UTM_COOKIE_NAME: string = 'utm_cookie';
const UTM_COOKIE_EXPIRES = Date.now() + 1000 * 60 * 60 * 24;

const cookies: Cookies = new Cookies();

const getUtm = (): { [key: string]: string } => {
  const utms = {};
  const searchParams = new URLSearchParams(window.location.search);
  for (const key of searchParams.keys()) {
    if (key.toLowerCase().startsWith('utm_')) {
      utms[key] = new URLSearchParams(window.location.search).get(key);
    }
  }
  return utms;
};

const formatUrlParams = (params: { [key: string]: string }): string => {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`,
    )
    .join('&');
};

export const getUtmCookie = (): { [key: string]: string } | undefined =>
  cookies.get(UTM_COOKIE_NAME);

export const setUtmToCookie = (): void => {
  const UTM_GROUP = getUtm();

  const checkForEmpty = Object.values(UTM_GROUP).filter(Boolean);

  if (checkForEmpty.length !== 0) {
    cookies.set(UTM_COOKIE_NAME, UTM_GROUP, {
      path: '/',
      expires: new Date(UTM_COOKIE_EXPIRES),
    });
  }
};

export const getUtmQueryString = (): string => {
  const utmCookie = getUtmCookie();
  return utmCookie ? `?${formatUrlParams(utmCookie)}` : '';
};
