import { Components, Theme } from '@mui/material';

export const muiFormTheme: Components<Theme> = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        marginTop: '14px !important',
      },
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      root: ({ theme: _theme }: { theme: Theme }) => ({
        border: 'solid 2px green',
        marginTop: 16,
        '.MuiFormControlLabel-root': {
          marginBottom: 8,
        },
      }),
    },
  },
};
