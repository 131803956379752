import { PayloadAction } from '@reduxjs/toolkit';
import {
  SagaReturnType,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { sendUserId } from '../../helpers/trackers';
import { LoginRequest } from '../interfaces/loginRequest.interface';
import { RegisterRequest } from '../interfaces/registerRequest.interface';

import {
  getUser,
  requestPasswordReset,
  resetPassword,
  userChange,
  userLogin,
  userLogout,
  userRegister,
} from '../reducers/userSlice';
import { AppState } from '../store';
import { User } from '../interfaces/userState.interface';
import { ResetPassword } from '../interfaces/resetPassword.interface';

export function* userWatcher() {
  yield takeLatest(userRegister, registerWorker);
  yield takeLatest(userLogin, loginWorker);
  yield takeLatest(userLogout, logoutWorker);
  yield takeLatest(getUser, getUserWorker);
  yield takeLatest(userChange, updateUserWorker);
  yield takeLatest(requestPasswordReset, requestPasswordResetWorker);
  yield takeLatest(resetPassword, resetPasswordWorker);
}

function* requestPasswordResetWorker({
  payload: email,
}: PayloadAction<string>) {
  yield put(requestPasswordReset.request());

  yield call(
    fetch,
    `${process.env.REACT_APP_BACKEND_URL}/api/referrers/auth/resetPasswordRequest`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.toLowerCase(),
      }),
    },
  );

  yield put(requestPasswordReset.fulfill());
}

function* resetPasswordWorker({ payload }: PayloadAction<ResetPassword>) {
  yield put(resetPassword.request());

  yield call(
    fetch,
    `${process.env.REACT_APP_BACKEND_URL}/api/referrers/auth/resetPassword`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: payload.password,
        code: payload.code,
      }),
    },
  );

  yield put(resetPassword.fulfill());
}

function* updateUserWorker() {
  const userState: AppState['user'] = yield select(
    (state: AppState) => state.user,
  );

  yield put(userChange.request());

  const updateResponse: SagaReturnType<typeof fetch> = yield call(
    fetch,
    `${process.env.REACT_APP_BACKEND_URL}/api/referrers`,
    {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: userState.firstName,
        lastName: userState.lastName,
        email: userState.email,
      }),
    },
  );

  if (updateResponse.status === 200) {
    const userData: User = yield updateResponse.json();

    yield put(userChange.success(userData));
  } else {
    yield put(userChange.failure('Failed to update user'));
  }

  yield put(userChange.fulfill());
}

function* logoutWorker() {
  yield call(
    fetch,
    `${process.env.REACT_APP_BACKEND_URL}/api/referrers/auth/logout`,
    {
      method: 'POST',
      credentials: 'include',
    },
  );
}

function* getUserWorker() {
  yield put(getUser.request());

  try {
    const authResponse: SagaReturnType<typeof fetch> = yield call(
      fetch,
      `${process.env.REACT_APP_BACKEND_URL}/api/referrers/current`,
      {
        credentials: 'include',
      },
    );

    if (authResponse.status === 200) {
      const userData: User = yield authResponse.json();

      yield put(getUser.success(userData));

      sendUserId(userData.id);
    } else {
      yield put(getUser.failure(authResponse.statusText));
    }
  } catch (e) {
    yield put(getUser.failure(e.message));
  }

  yield put(getUser.fulfill());
}

function* registerWorker(action: PayloadAction<RegisterRequest>) {
  yield put(userRegister.request());

  const authResponse: SagaReturnType<typeof fetch> = yield call(
    fetch,
    `${process.env.REACT_APP_BACKEND_URL}/api/referrers/register`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        email: action.payload.email,
        password: action.payload.password,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      }),
    },
  );

  if (authResponse.status === 201) {
    const userData: SagaReturnType<typeof authResponse.json> =
      yield authResponse.json();

    yield put(userRegister.success(userData));
    yield put(
      userLogin({
        email: action.payload.email,
        password: action.payload.password,
      }),
    );
  } else {
    const errorData: SagaReturnType<typeof authResponse.json> =
      yield authResponse.json();
    yield put(userRegister.failure(errorData));
  }

  yield put(userRegister.fulfill());
}

function* loginWorker(action: PayloadAction<LoginRequest>) {
  yield put(userLogin.request());

  try {
    const authResponse: SagaReturnType<typeof fetch> = yield call(
      fetch,
      `${process.env.REACT_APP_BACKEND_URL}/api/referrers/auth`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          email: action.payload.email,
          password: action.payload.password,
        }),
      },
    );

    if (authResponse.status === 201) {
      const userData: SagaReturnType<typeof authResponse.json> =
        yield authResponse.json();

      sendUserId(userData.id);

      yield put(userLogin.success(userData));
    } else {
      const errorData: SagaReturnType<typeof authResponse.json> =
        yield authResponse.json();
      yield put(userLogin.failure(errorData));
    }
  } catch (e) {
    yield put(userLogin.failure('Не получилось залогиниться'));
  }

  yield put(userLogin.fulfill());
}
