import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CheckboxIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: 24, height: 24 }} {...props}>
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#4520AB"
      />
      <path
        d="M8 11.9387L10.7083 14.666L16.6667 8.66602"
        stroke="#F8F6FC"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
