import { Theme } from '@mui/material';

export const styles = {
  root: (theme: Theme) => ({
    position: 'relative',
    backgroundColor: 'kit.background.white',
    zIndex: (theme: Theme) => theme.zIndex.appBar,
    borderRadius: 0,
    height: 60,
    [theme.breakpoints.down('md')]: { height: '48px' },
  }),
  container: (theme: Theme) => ({
    padding: '0 60px',
    [theme.breakpoints.down('xl')]: {
      padding: '0',
    },
  }),
  toolbar: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('xl')]: {
      padding: '11px 0',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '0 8px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px 0 16px',
    },
  }),
  linkForLogo: {
    marginTop: '3px',
  },
  elbrusLogoIcon: {
    width: '104px',
    height: '34px',
    verticalAlign: 'middle',
  },
  navbar: (theme: Theme) => ({
    ml: '66px',
    [theme.breakpoints.down('lg')]: {
      ml: 8,
      display: 'flex',
      minWidth: '600px',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
      maxWidth: '535px',
    },
  }),
  navbarLinks: {
    color: 'kit.link.main',
    my: 1,
    mx: 1.5,
    textDecoration: 'none',
  },
};
