import { Components, Theme } from '@mui/material';

export const muiTabTheme: Components<Theme> = {
  MuiTab: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        fontFamily: 'Inter',
        fontSize: 18,
        fontWeight: 400,
        textTransform: 'capitalize',
        color: theme.palette.kit.text.secondary,
        // border: "solid",
        padding: 0,
        '&.MuiButtonBase-root.Mui-selected': {
          color: theme.palette.kit.text.h,
        },
      }),
    },
  },
};
