import { SvgIcon, SvgIconProps } from '@mui/material';

export default function MapPinIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.47445 9.50302C8.83212 10.1473 7.9562 10.5573 7.0219 10.5573C6.0292 10.5573 5.15328 10.1473 4.56934 9.50302C3.92701 8.85874 3.51825 7.98019 3.51825 7.04307C3.51825 5.11025 5.09489 3.52885 7.0219 3.52885C8.9489 3.52885 10.5255 5.11025 10.5255 7.04307C10.5255 7.98019 10.1168 8.85874 9.47445 9.50302ZM11.9854 2.0646C9.24088 -0.6882 4.80292 -0.6882 2.05839 2.0646C-0.686131 4.8174 -0.686131 9.26873 2.05839 12.0215L7.0219 17L11.9854 12.0215C14.6715 9.26873 14.6715 4.8174 11.9854 2.0646Z"
          fill="#FFBC5B"
        />
      </svg>
    </SvgIcon>
  );
}
