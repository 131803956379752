import { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import { LoginModalState } from '../../redux/interfaces/userState.interface';
import { ErrorField } from '../MainModal/MainModal';

interface ResetPasswordProps {
  passwordValue: string;
  passwordError: ErrorField;
  loginModalState: LoginModalState;
  canResetPassword: boolean;
  handleResetPassword: () => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
  setField: (field: string, value: string) => void;
}

export default function ResetPasswordModal(props: ResetPasswordProps) {
  const [visibilityPass, setVisibilityPass] = useState(false);

  const {
    passwordValue,
    passwordError,
    loginModalState,
    canResetPassword,
    handleResetPassword,
    onBlurHandler,
    setField,
  } = props;

  return (
    <>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          <>
            <Typography
              sx={{ letterSpacing: -2, color: 'kit.text.main' }}
              variant="desktopH3"
            >
              Сбросить пароль
            </Typography>
            <Box sx={{ mx: 2, mt: 2 }}>
              <Typography
                variant="desktopP1"
                sx={(theme) => ({
                  letterSpacing: -0.5,
                  color: 'kit.text.main',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                  },
                  textAlign: 'center',
                })}
              >
                Введите новый пароль для вашего аккаунта
              </Typography>
            </Box>
          </>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'}>
          <Grid item sx={{ position: 'relative' }}>
            <TextField
              variant="standard"
              sx={{ my: 1 }}
              type={!visibilityPass ? 'password' : ''}
              id="password"
              error={true}
              label="Пароль"
              placeholder="*******"
              fullWidth
              value={passwordValue}
              onChange={(e) => setField(e.target.id, e.target.value)}
              onBlur={onBlurHandler}
              helperText={passwordError.isError && passwordError.message}
            />
            {visibilityPass ? (
              <VisibilityIcon
                sx={{
                  color: passwordValue ? 'kit.stroke.black' : 'gray',
                  ml: -3,
                  zIndex: 2,
                  cursor: passwordValue ? 'pointer' : null,
                  position: 'absolute',
                  top: 20,
                  right: 5,
                }}
                onClick={() =>
                  setVisibilityPass((prev) => (passwordValue ? !prev : prev))
                }
              />
            ) : (
              <VisibilityOffIcon
                sx={{
                  color: passwordValue ? 'kit.stroke.black' : 'gray',
                  ml: -3,
                  zIndex: 2,
                  cursor: passwordValue ? 'pointer' : null,
                  position: 'absolute',
                  top: 20,
                  right: 5,
                }}
                onClick={() =>
                  setVisibilityPass((prev) => (passwordValue ? !prev : prev))
                }
              />
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box
          sx={{
            py: 1,
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {loginModalState.isLoading && <CircularProgress />}
          <Button
            disabled={!canResetPassword}
            variant="kitPrimary"
            onClick={handleResetPassword}
          >
            Сохранить новый пароль
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
