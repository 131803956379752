import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import { CssBaseline } from '@mui/material';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <CssBaseline />
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
}
