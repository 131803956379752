import {
  AppBar,
  Box,
  Container,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import Link from '../../../kit/Link/Link';
import MainModal from '../../MainModal/MainModal';
import { ElbrusRusLogoIcon } from '../../../kit/icons/ElbrusRusLogoIcon';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import ProfileMenu from '../../ProfileMenu/ProfileMenu';
export const arrLinks = [
  {
    title: 'Программы',
    href: 'https://elbrusboot.camp/#program',
    isVisibleBelowLg: true,
  },
  {
    title: 'Бесплатные мероприятия',
    href: 'https://elbrusboot.camp/events/',
    isVisibleBelowLg: true,
    canBeActive: true,
  },
  {
    title: 'О буткемпе',
    href: 'https://elbrusboot.camp/about/',
    isVisibleBelowLg: false,
    canBeActive: true,
  },

  {
    title: 'Статьи для новичков',
    href: 'https://elbrusboot.camp/blog/',
    isVisibleBelowLg: true,
    isExternal: true,
    canBeActive: false,
  },
  {
    title: 'Контакты',
    href: 'https://elbrusboot.camp/contacts/',
    isVisibleBelowLg: true,
    canBeActive: true,
  },
  {
    title: 'F.A.Q.',
    href: 'https://elbrusboot.camp/faq/',
    isVisibleBelowLg: true,
    canBeActive: true,
  },
];
export default function Navbar({
  lkLink = 'https://edu.elbrusboot.camp/',
}: any) {
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isBelowLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  );
  const [isMediaQueryReady, setIsMediaQueryReady] = useState(false);

  useEffect(() => {
    if (!isMediaQueryReady) {
      setIsMediaQueryReady(true);
    }
  }, [isBelowLg, isMd]);
  return (
    <>
      <MainModal />
      <AppBar component="div" elevation={0} sx={styles.root}>
        <Container maxWidth="xl" disableGutters sx={styles.container}>
          <Toolbar variant="dense" sx={styles.toolbar}>
            <Link
              variant="desktopP3"
              to="https://elbrusboot.camp/"
              underline="hover"
              sx={styles.linkForLogo}
            >
              <ElbrusRusLogoIcon sx={styles.elbrusLogoIcon} />
            </Link>
            <Box sx={styles.navbar}>
              {arrLinks
                .filter((link) => !isBelowLg || link.isVisibleBelowLg)
                .map((link) => {
                  return (
                    <Link
                      key={link.href}
                      variant="desktopP3"
                      to={link.href}
                      target={link.title === 'Блог' ? '_blank' : '_self'}
                      underline="hover"
                      sx={styles.navbarLinks}
                    >
                      {link.title}
                    </Link>
                  );
                })}
            </Box>
            <Box sx={{ ml: 'auto' }}>
              <ProfileMenu />
            </Box>
            {isMd && isMediaQueryReady && <BurgerMenu lkLink={lkLink} />}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
