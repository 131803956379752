import { Components, Theme } from '@mui/material';

export const muiCssBaselineTheme: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: `
        body > #root {
            min-height: 100vh; 
            margin: 0; 
  
            display: grid;
            grid-template-rows: auto auto 1fr auto;
        }

        @font-face {
            font-family: "RF Rostin";
            src: url("/fonts/RF-Rostin-Regular.woff") format("woff");
            font-style: normal;
            font-weight: normal;
        }

        @font-face {
            font-family: "RF Rostin";
            src: url("/fonts/RF-Rostin-Bold.woff") format("woff");
            font-style: normal;
            font-weight: bold;
        }

        @font-face {
            font-family: "RF Rostin";
            src: url("/fonts/RF-Rostin-Light.woff") format("woff");
            font-style: italic;
            font-weight: normal;
        }
      `,
  },
};
