export const styles = {
  dialogInnerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 6,
  },
  titleTypography: {
    letterSpacing: -2,
    color: 'kit.text.main',
  },
  helperBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 1,
  },
  helperTypography1: (theme) => ({
    letterSpacing: -0.5,
    mr: -1,
    color: 'kit.text.main',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  }),
  helperTypography2: (theme) => ({
    letterSpacing: -0.5,
    color: 'kit.text.h',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    cursor: 'pointer',
  }),
  textFieldGrid: { my: 1 },
  textField1: { mb: 3, pr: 2, width: '100%' },
  textField2: { mb: 3, width: '100%' },
  maskedInput: { my: 1 },
  textField3: { my: 1 },
  icon: (passwordValue) => ({
    color: passwordValue ? 'kit.stroke.black' : 'gray',
    ml: -3,
    zIndex: 2,
    cursor: passwordValue ? 'pointer' : null,
    position: 'absolute',
    top: 32,
    right: 5,
  }),
  checkboxBox: { display: 'flex', alignItems: 'baseline', mt: 2 },
  agreeTypography: (theme) => ({
    mt: 1,
    letterSpacing: -0.5,
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  }),
  actionsBox: {
    py: 1,
    mb: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
};
