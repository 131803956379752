import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  styled,
} from '@mui/material';

const StyledAnchor = styled('a')({});

export type LinkProps = {
  activeClassName?: string;
  to: string;
  noLinkStyle?: boolean;
} & Omit<MuiLinkProps, 'href'> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function Link(props, ref) {
    const {
      activeClassName = 'active',
      className: classNameProps,
      to,
      noLinkStyle,
      ...other
    } = props;

    const location = useLocation();
    const className = clsx(classNameProps, {
      [activeClassName]: location.pathname === to && activeClassName,
    });

    const isExternal =
      typeof to === 'string' &&
      (to.indexOf('http') === 0 || to.indexOf('mailto:') === 0);

    if (isExternal) {
      if (noLinkStyle) {
        return (
          <StyledAnchor
            className={className}
            href={to}
            ref={ref as React.Ref<HTMLAnchorElement>}
            {...(other as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
          />
        );
      }

      return <MuiLink className={className} href={to} ref={ref} {...other} />;
    }

    if (noLinkStyle) {
      return <RouterLink className={className} to={to} ref={ref} {...other} />;
    }

    return (
      <MuiLink
        component={RouterLink}
        to={to}
        className={className}
        ref={ref}
        {...other}
      />
    );
  },
);

export default Link;
