import { Components, Theme } from '@mui/material';

declare module '@mui/material/Button' {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    kitPrimary: true;
    inverse: true;
  }

  interface ButtonPropsSizeOverrides {
    smallest: true;
  }
}

export const muiButtonTheme: Components<Theme> = {
  MuiButton: {
    variants: [
      {
        props: { variant: 'kitPrimary', size: 'large' },
        style: ({ theme }: { theme: Theme }) => ({
          ':hover': {
            backgroundColor: theme.palette.kit.accent,
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          ':active': {
            backgroundColor: theme.palette.kit.button.primary.active,
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          backgroundColor: theme.palette.kit.accent,
          borderRadius: 20,
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 32,
          paddingRight: 32,
          color: theme.palette.kit.text.main,
          lineHeight: 1.19,
          fontSize: 16,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
        }),
      },
      {
        props: { variant: 'kitPrimary', size: 'medium' },
        style: ({ theme }: { theme: Theme }) => ({
          ':hover': {
            backgroundColor: theme.palette.kit.accent,
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          ':active': {
            backgroundColor: 'KIT_BUTTON_RPIMARY_ACTIVE',
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          backgroundColor: theme.palette.kit.accent,
          borderRadius: 16,
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 24,
          paddingRight: 24,
          color: theme.palette.kit.text.main,
          lineHeight: 1.25,
          fontSize: 14,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
        }),
      },
      {
        props: { variant: 'kitPrimary', size: 'small' },
        style: ({ theme }: { theme: Theme }) => ({
          ':hover': {
            backgroundColor: theme.palette.kit.accent,
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          ':active': {
            backgroundColor: theme.palette.kit.button.primary.active,
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          backgroundColor: theme.palette.kit.accent,
          borderRadius: 12,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          color: theme.palette.kit.text.main,
          lineHeight: 1.25,
          fontSize: 14,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
        }),
      },
      {
        props: { variant: 'kitPrimary', size: 'smallest' },
        style: ({ theme }) => ({
          ':hover': {
            backgroundColor: theme.palette.kit.accent,
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          ':active': {
            backgroundColor: theme.palette.kit.button.primary.active,
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          backgroundColor: theme.palette.kit.accent,
          borderRadius: 12,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 12,
          paddingRight: 12,
          color: theme.palette.kit.text.main,
          lineHeight: 1.25,
          fontSize: 12,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
          textTransform: 'uppercase',
        }),
      },
      {
        props: { variant: 'outlined', size: 'large' },
        style: ({ theme }: { theme: Theme }) => ({
          ':hover': {
            border: '2px solid #0F0F0F',
            boxShadow: '0px 4px 20px rgba(205, 205, 205, 0.7)',
          },
          ':active': {
            boxShadow: '0px 4px 20px rgba(205, 205, 205, 0.7)',
          },
          border: '2px solid #0F0F0F',
          borderRadius: 20,
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 32,
          paddingRight: 32,
          color: theme.palette.kit.text.main,
          lineHeight: 1.25,
          fontSize: 16,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
        }),
      },
      {
        props: { variant: 'outlined', size: 'medium' },
        style: ({ theme }: { theme: Theme }) => ({
          ':hover': {
            border: '2px solid #0F0F0F',
            boxShadow: '0px 4px 20px rgba(205, 205, 205, 0.7)',
          },
          ':active': {
            boxShadow: '0px 4px 20px rgba(205, 205, 205, 0.7)',
          },
          border: '2px solid #0F0F0F',
          borderRadius: 16,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          color: theme.palette.kit.text.main,
          lineHeight: 1.25,
          fontSize: 14,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
        }),
      },
      {
        props: { variant: 'outlined', size: 'small' },
        style: ({ theme }: { theme: Theme }) => ({
          ':hover': {
            border: '2px solid #0F0F0F',
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          ':active': {
            boxShadow: '0px 4px 20px rgba(255, 188, 91, 0.7)',
          },
          border: '2px solid #0F0F0F',
          borderRadius: 16,
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 24,
          paddingRight: 24,
          color: theme.palette.kit.text.main,
          lineHeight: 1.25,
          fontSize: 14,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
        }),
      },
      {
        props: { variant: 'outlined', size: 'smallest' },
        style: ({ theme }) => ({
          ':hover': {
            border: '2px solid #0F0F0F',
            boxShadow: '0px 4px 20px rgba(205, 205, 205, 0.7)',
          },
          ':active': {
            boxShadow: '0px 4px 20px rgba(205, 205, 205, 0.7)',
          },
          border: '2px solid #0F0F0F',
          borderRadius: 12,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 12,
          paddingRight: 12,
          color: theme.palette.kit.text.main,
          lineHeight: 1.25,
          fontSize: 12,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
          textTransform: 'uppercase',
        }),
      },
      {
        props: { variant: 'inverse', size: 'large' },
        style: ({ theme }: { theme: Theme }) => ({
          ':hover': {
            backgroundColor: theme.palette.kit.background.white,
            boxShadow: '0px 4px 8px rgba(191, 163, 123, 0.58);',
            color: theme.palette.kit.text.main,
          },
          ':active': {
            backgroundColor: theme.palette.kit.background.white,
            color: theme.palette.kit.text.main,
          },
          border: '2px solid #FFFFFF',
          borderRadius: 20,
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 32,
          paddingRight: 32,
          color: theme.palette.kit.background.lightpurple,
          lineHeight: 1.25,
          fontSize: 16,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
        }),
      },
      {
        props: { variant: 'inverse', size: 'medium' },
        style: ({ theme }: { theme: Theme }) => ({
          ':hover': {
            backgroundColor: theme.palette.kit.background.white,
            boxShadow: '0px 4px 8px rgba(191, 163, 123, 0.58);',
            color: theme.palette.kit.text.main,
          },
          ':active': {
            backgroundColor: theme.palette.kit.background.white,
            color: theme.palette.kit.text.main,
          },
          border: '2px solid #FFFFFF',
          borderRadius: 16,
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 24,
          paddingRight: 24,
          color: theme.palette.kit.background.lightpurple,
          lineHeight: 1.25,
          fontSize: 14,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
        }),
      },
      {
        props: { variant: 'inverse', size: 'small' },
        style: ({ theme }: { theme: Theme }) => ({
          ':hover': {
            backgroundColor: theme.palette.kit.background.white,
            boxShadow: '0px 4px 8px rgba(191, 163, 123, 0.58);',
            color: theme.palette.kit.text.main,
          },
          ':active': {
            backgroundColor: theme.palette.kit.background.white,
            color: theme.palette.kit.text.main,
          },
          border: '2px solid #FFFFFF',
          borderRadius: 16,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          color: theme.palette.kit.background.lightpurple,
          lineHeight: 1.25,
          fontSize: 14,
          fontWeight: 700,
          fontFamily: 'RF Rostin',
        }),
      },
    ],
  },
};
