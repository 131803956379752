import { Theme } from '@mui/material';

export const styles = {
  avatarBase: (theme: Theme) => ({
    ml: 1.5,
    color: 'kit.text.main',
    bgcolor: 'kit.background.lightpurple',
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderColor: 'kit.stroke.gray',
    height: '40px',
    width: '40px',
    [theme.breakpoints.down('md')]: {
      height: '32px',
      width: '32px',
      mr: 3,
      ml: 1,
    },
  }),
  avatarDefault: {
    borderColor: 'kit.stroke.gray',
    ':hover': {
      borderColor: 'kit.stroke.turquoise',
    },
  },
  avatarActive: {
    borderColor: 'kit.stroke.turquoise',
  },
};
