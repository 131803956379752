import { Components, Theme } from '@mui/material';

export const muiInputTheme: Components<Theme> = {
  MuiInput: {
    defaultProps: {},
    styleOverrides: {
      root: ({ theme }) => ({
        '&:has(> .MuiSelect-select)::after': {
          borderBottomColor: theme.palette.kit.infographic.orange,
        },
        '&:has(> .MuiSelect-select):hover:not(.Mui-disabled):before': {
          borderBottomColor: theme.palette.kit.infographic.orange,
        },
      }),
    },
  },
};
