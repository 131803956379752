import { Components, Theme } from '@mui/material';

export const muiChipTheme: Components<Theme> = {
  MuiChip: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        margin: '8px 0',
        textTransform: 'uppercase',
        borderRadius: 8,
        fontSize: 14,
        lineHeight: 1.25,
        fontWeight: 700,
        fontFamily: 'RF Rostin',
        '&:not(:last-child)': {
          marginRight: 12,
        },
        '&.MuiChip-colorPrimary': {
          backgroundColor: theme.palette.kit.main,
          color: theme.palette.kit.accent2,
          '& .MuiSvgIcon-root': {
            '&.MuiSvgIcon-colorSecondary': {
              color: theme.palette.kit.accent2,
            },
            '&.MuiSvgIcon-colorPrimary': {
              color: theme.palette.kit.accent2,
            },
          },
        },
        '&.MuiChip-colorSecondary': {
          backgroundColor: theme.palette.kit.accent2,
          color: theme.palette.kit.text.h,
          '& .MuiSvgIcon-root': {
            '&.MuiSvgIcon-colorSecondary': {
              color: theme.palette.kit.text.h,
            },
            '&.MuiSvgIcon-colorPrimary': {
              color: theme.palette.kit.text.h,
            },
          },
        },
        '&.MuiChip-outlined': {
          backgroundColor: 'transparent',
          color: theme.palette.kit.text.h,
          border: '2px solid #4520AB;',
          '& .MuiSvgIcon-root': {
            '&.MuiSvgIcon-colorSecondary': {
              color: theme.palette.kit.text.h,
            },
            '&.MuiSvgIcon-colorPrimary': {
              color: theme.palette.kit.text.h,
            },
          },
        },
      }),
    },
  },
};
