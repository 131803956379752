import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import {
  ErrorState,
  PartialUser,
  User,
  UserState,
} from '../interfaces/userState.interface';
import { LoginRequest } from '../interfaces/loginRequest.interface';
import { RegisterRequest } from '../interfaces/registerRequest.interface';
import { ResetPassword } from '../interfaces/resetPassword.interface';

const initialState: UserState = {
  isLoading: true,
  loginModal: { isOpen: false, isLoading: false, error: null, email: null },
};

const authLoginPayloadCreators = {
  trigger: (payload: LoginRequest) => payload,
  success: (payload: PartialUser) => payload,
  failure: (payload: string) => payload,
};
export const userLogin = createRoutine('USER_LOGIN', authLoginPayloadCreators);

const userRegisterPayloadCreators = {
  trigger: (payload: RegisterRequest) => payload,
  success: (payload: PartialUser) => payload,
  failure: (payload: string) => payload,
};
export const userRegister = createRoutine(
  'USER_REGISTER',
  userRegisterPayloadCreators,
);

const userUpdatePayloadCreators = {
  success: (payload: PartialUser) => payload,
  failure: (payload: string) => payload,
};
export const userChange = createRoutine(
  'USER_UPDATE',
  userUpdatePayloadCreators,
);

const getUserPayloadCreators = {
  success: (payload: PartialUser) => payload,
  failure: (payload: string) => payload,
};
export const getUser = createRoutine('GET_USER_DATA', getUserPayloadCreators);

const requestPasswordResetCreators = {
  trigger: (payload: string) => payload,
};
export const requestPasswordReset = createRoutine(
  'REQUEST_PASSWORD_RESET',
  requestPasswordResetCreators,
);

const resetPasswordCreators = {
  trigger: (payload: ResetPassword) => payload,
};
export const resetPassword = createRoutine(
  'RESET_PASSWORD',
  resetPasswordCreators,
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    openLoginWindow: (state) => {
      state.loginModal.isOpen = true;
    },
    resetLoginError: (state) => {
      state.loginModal.error = null;
    },
    userLogout: (state) => {
      delete state['id'];
      delete state['firstName'];
      delete state['lastName'];
      delete state['email'];
      delete state['transactions'];
      state.loginModal.isOpen = true;
    },
    setUser: (state, action: PayloadAction<PartialUser>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: {
    [userRegister.TRIGGER]: (state) => {
      state.loginModal.isLoading = true;
    },
    [userRegister.SUCCESS]: (state, action: PayloadAction<User>) => {
      return { ...state, ...action.payload };
    },
    [userRegister.FAILURE]: (state, action: PayloadAction<ErrorState>) => {
      state.loginModal.error = action.payload;
    },
    [userRegister.FULFILL]: (state) => {
      state.loginModal.isLoading = false;
    },
    [userLogin.TRIGGER]: (state) => {
      state.loginModal.isLoading = true;
    },
    [userLogin.SUCCESS]: (state, action: PayloadAction<User>) => {
      state.loginModal.error = null;
      return { ...state, ...action.payload };
    },
    [userLogin.FAILURE]: (state, action: PayloadAction<ErrorState>) => {
      state.loginModal.error = action.payload;
    },
    [userLogin.FULFILL]: (state) => {
      state.loginModal.isLoading = false;
    },
    [userChange.SUCCESS]: (state, action: PayloadAction<User>) => {
      return { ...state, ...action.payload };
    },
    [getUser.TRIGGER]: (state) => {
      state.isLoading = true;
    },
    [getUser.SUCCESS]: (state, action) => {
      return { ...state, ...action.payload };
    },
    [getUser.FAILURE]: (state) => {
      state.loginModal.isOpen = true;
    },
    [getUser.FULFILL]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { openLoginWindow, userLogout, setUser, resetLoginError } =
  userSlice.actions;

export default userSlice.reducer;
