import { Components, Theme } from '@mui/material';

export const muiTextFieldTheme: Components<Theme> = {
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
      color: 'primary',
    },
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        '.MuiInputLabel-root': {
          fontSize: 13,
        },
        '.MuiInput-root.MuiInputBase-colorPrimary input::placeholder': {
          opacity: 0,
        },
        '.MuiInput-root.MuiInputBase-colorPrimary': {
          color: theme.palette.kit.text.main,
          '&:before': {
            borderBottomColor: theme.palette.kit.stroke.black,
          },
          '&:not(:hover)': {
            color: theme.palette.kit.text.main,
          },
          '&:hover:not(.Mui-focused):before': {
            borderBottomColor: theme.palette.kit.infographic.orange,
          },
          '&:after': {
            borderBottomColor: theme.palette.kit.infographic.orange,
          },
        },
        '.MuiFormLabel-root.MuiFormLabel-colorPrimary': {
          '.Mui-focused': {
            color: theme.palette.kit.text.secondaryInverse,
          },
          color: theme.palette.kit.text.secondary,
        },

        '.MuiInput-root.MuiInputBase-colorSecondary input::placeholder': {
          opacity: 0,
        },
        '.MuiInput-root.MuiInputBase-colorSecondary': {
          color: theme.palette.kit.infographic.orange,
          '&:before': {
            borderBottomColor: theme.palette.kit.stroke.gray,
          },
          '&:not(:hover)': {
            color: theme.palette.kit.text.mainInverse,
          },
          '&:hover:not(.Mui-focused):before': {
            borderBottomColor: theme.palette.kit.infographic.orange,
          },
          '&:after': {
            borderBottomColor: theme.palette.kit.infographic.orange,
          },
        },
        '.MuiFormLabel-root.MuiFormLabel-colorSecondary': {
          '.Mui-focused': {
            color: theme.palette.kit.text.secondaryInverse,
          },
          color: theme.palette.kit.text.mainInverse,
        },
      }),
    },
  },
};
