import { SvgIcon, SvgIconProps } from '@mui/material';

export function RubleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="107" height="119" viewBox="0 0 107 119" {...props}>
      <path
        opacity="0.1"
        d="M61.9272 76.654H0.181152V57.6897H61.3629C66.3606 57.6897 70.3373 56.9063 73.2929 55.3394C76.3023 53.7726 78.4787 51.6114 79.8222 48.8559C81.1657 46.0464 81.8374 42.8587 81.8374 39.2927C81.8374 35.7808 81.1119 32.5661 79.661 29.6485C78.2638 26.6769 76.0874 24.2996 73.1317 22.5166C70.2298 20.6796 66.5218 19.7611 62.0078 19.7611H40.6466V118.716H16.464V0.71582H61.9272C71.1703 0.71582 79.0699 2.36371 85.626 5.6595C92.2359 8.95529 97.3142 13.4938 100.861 19.2749C104.408 25.056 106.181 31.6746 106.181 39.1307C106.181 46.9649 104.354 53.6916 100.7 59.3106C97.0993 64.9297 91.9941 69.225 85.3842 72.1966C78.828 75.1682 71.009 76.654 61.9272 76.654ZM66.3606 83.2996V102.264H0.181152V83.2996H66.3606Z"
        fill="#4520AB"
      />
    </SvgIcon>
  );
}
