import { Components, Theme } from '@mui/material';

export const muiPaperTheme: Components<Theme> = {
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
      },
    },
  },
};
