import React from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AppState } from '../../redux/store';
import { ChevronUpIcon } from '../../kit/icons/ChevronUpIcon';
import { userLogout } from '../../redux/reducers/userSlice';
import { UserState } from '../../redux/interfaces/userState.interface';
import { styles } from './styles';

function ProfileMenuLoading() {
  return <CircularProgress />;
}

type ProfileMenuLoggedProps = {
  user: UserState;
  handleLogout: () => void;
};

function ProfileMenuLogged({ user, handleLogout }: ProfileMenuLoggedProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box>
        <Button
          disableElevation={true}
          disableRipple={true}
          onClick={handleClick}
          sx={{ ':hover': { background: 'none' } }}
        >
          <ChevronUpIcon
            sx={{
              transitionDuration: '0.25s',
              transitionProperty: 'transform',
              transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
          <Avatar sx={styles.avatarBase}>
            {(user?.firstName && user?.firstName[0]) || 'К'}
          </Avatar>
        </Button>
      </Box>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        disableAutoFocusItem={true}
      >
        <MenuItem onClick={handleLogout} sx={{ p: '8px 8px 0 8px' }}>
          <Typography variant="desktopP3">Выйти</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default function ProfileMenu() {
  const dispatch = useAppDispatch();

  const userState = useAppSelector((state: AppState) => state.user);

  const handleLogout = () => {
    dispatch(userLogout());
  };

  return (
    <>
      {userState.isLoading && <ProfileMenuLoading />}
      {!userState.isLoading && userState.id && (
        <ProfileMenuLogged user={userState} handleLogout={handleLogout} />
      )}
    </>
  );
}
