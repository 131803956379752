import { Components, Theme } from '@mui/material';

export const muiLinkTheme: Components<Theme> = {
  MuiLink: {
    variants: [
      {
        props: { variant: 'kitPrimary' },
        style: ({ theme }: { theme: Theme }) => ({
          fontSize: 16,
          fontFamily: 'Inter',
          color: theme.palette.kit.link.main,
          textDecoration: 'none',
          ':after': {
            content: '"→"',
          },
          ':hover': {
            textDecoration: 'underline',
          },
          ':active': {
            textDecoration: 'none',
          },
        }),
      },
      {
        props: { variant: 'kitPrimaryInverse' },
        style: ({ theme }: { theme: Theme }) => ({
          fontSize: 16,
          fontFamily: 'Inter',
          color: theme.palette.kit.link.inverse,
          textDecoration: 'none',
          ':after': {
            content: '"→"',
          },
          ':hover': {
            textDecoration: 'underline',
          },
          ':active': {
            textDecoration: 'none',
          },
        }),
      },
      {
        props: { variant: 'kitSecondary' },
        style: ({ theme }: { theme: Theme }) => ({
          fontSize: 16,
          fontFamily: 'Inter',
          color: theme.palette.kit.link.main,
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
          },
          ':active': {
            textDecoration: 'none',
          },
        }),
      },
      {
        props: { variant: 'kitSecondaryInverseWhite' },
        style: ({ theme }) => ({
          fontSize: 16,
          fontFamily: 'Inter',
          lineHeight: 1.25,
          color: theme.palette.kit.link.inverseWhite,
          opacity: 0.6,
          textDecoration: 'none',
          ':after': {
            content: '"→"',
            fontSize: 'inherit',
            fontFamily: 'Inter',
          },
          ':hover': {
            textDecoration: 'underline',
          },
          ':active': {
            textDecoration: 'none',
          },
        }),
      },
      {
        props: { variant: 'kitSecondaryInverse' },
        style: ({ theme }: { theme: Theme }) => ({
          fontSize: 16,
          fontFamily: 'Inter',
          color: theme.palette.kit.link.inverse,
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
          },
          ':active': {
            textDecoration: 'none',
          },
        }),
      },
    ],
  },
};
