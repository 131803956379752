import { SxProps, Theme } from '@mui/system';

export const styles = {
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 6,
  } as SxProps<Theme>,

  typography: {
    letterSpacing: -2,
    color: 'kit.text.main',
  } as SxProps<Theme>,

  textBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  } as SxProps<Theme>,

  registrationTypography: (theme: Theme) =>
    ({
      letterSpacing: -0.5,
      color: 'kit.text.main',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      cursor: 'pointer',
    }) as SxProps<Theme>,
  forgotPassword: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  textField: {
    my: 1,
  } as SxProps<Theme>,

  passwordIcon: (passwordValue: string) =>
    ({
      color: passwordValue ? 'kit.stroke.black' : 'gray',
      ml: -3,
      zIndex: 2,
      cursor: passwordValue ? 'pointer' : null,
      position: 'absolute',
      top: 32,
      right: 5,
    }) as SxProps<Theme>,

  forgetPasswordTypography: (theme: Theme) =>
    ({
      mt: 3,
      letterSpacing: -0.5,
      color: 'kit.text.h',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      cursor: 'pointer',
    }) as SxProps<Theme>,

  dialogActions: {
    justifyContent: 'center',
  } as SxProps<Theme>,

  buttonBox: {
    py: 1,
    mb: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  } as SxProps<Theme>,
};
