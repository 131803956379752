import { Box, Typography } from '@mui/material';
import styles from './styles';

export interface Item {
  date?: string;
  title?: string;
  amount?: number;
  comment?: string;
  type: 'income' | 'outcome';
}

interface BalanceItemProps {
  item: Item;
}

function BalanceItem({ item }: BalanceItemProps) {
  const { date, title, amount, comment, type } = item;

  return (
    <Box sx={styles.itemContainer}>
      <Box sx={styles.innerBlock}>
        <Box>
          <Typography variant="desktopP3" sx={styles.date}>
            {date}
          </Typography>
        </Box>
        <Box sx={styles.title}>
          <Typography variant="desktopP3">{title}</Typography>
        </Box>
      </Box>
      <Box sx={styles.innerBlock}>
        <Box>
          <Typography
            variant="desktopP3"
            sx={{
              ...styles.points,
              backgroundColor:
                type === 'income'
                  ? 'kit.background.success'
                  : 'kit.background.error',
              color: type === 'income' ? 'kit.text.success' : 'kit.text.error',
            }}
          >
            {type === 'income' ? '+' : '-'}
            {amount}
          </Typography>
        </Box>
        <Box sx={styles.commentBlock}>
          <Typography variant="desktopP3">{comment}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default BalanceItem;
