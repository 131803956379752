import { Components, Theme } from '@mui/material';
import { theme } from '../theme';

export const muiInputLabelTheme: Components<Theme> = {
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme: _theme }: { theme: Theme }) => ({
        fontSize: 13,
        '&.Mui-focused': {
          color: theme.palette.kit.text.secondary,
        },
      }),
    },
  },
};
