import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';
import userSlice from './reducers/userSlice';
import footerSubscriptionSlice from './reducers/footerSubscriptionSlice';

const sagaMiddleware = createSagaMiddleware();

export function makeStore() {
  const store = configureStore({
    reducer: {
      user: userSlice,
      footerSubscription: footerSubscriptionSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
  });
  sagaMiddleware.run(rootSaga);
  return store;
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
