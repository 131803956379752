import React from 'react';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import { createTheme } from '@mui/material';

import { muiTypographyTheme } from './components/muiTypographyTheme';
import { muiCssBaselineTheme } from './components/muiCssBaselineTheme';
import { muiAppBarTheme } from './components/muiAppBarTheme';
import { muiButtonTheme } from './components/muiButtonTheme';
import { muiMenuTheme } from './components/muiMenuTheme';
import { muiMenuItemTheme } from './components/muiMenuItemTheme';
import { muiLinkTheme } from './components/muiLinkTheme';
import { muiChipTheme } from './components/muiChipTheme';
import { muiStepLabelTheme } from './components/muiStepLabelTheme';
import { muiTabTheme } from './components/muiTabTheme';
import { muiTabsTheme } from './components/muiTabsTheme';
import { muiLinearProgressTheme } from './components/muiLinearProgressTheme';
import { muiAccordionSummaryTheme } from './components/muiAccordionSummaryTheme';
import { muiListTheme } from './components/muiListTheme';
import { muiListItemTheme } from './components/muiListItemTheme';
import { muiPaperTheme } from './components/muiPaperTheme';
import { muiAccordionTheme } from './components/muiAccordionTheme';
import { muiAccordionDetailsTheme } from './components/muiAccordionDetailsTheme';
import { muiTextFieldTheme } from './components/muiTextFieldTheme';
import { muiSelectTheme } from './components/muiSelectTheme';
import { muiFormTheme } from './components/muiFormTheme';
import { muiInputLabelTheme } from './components/muiInputLabelTheme';
import { muiCheckboxTheme } from './components/muiCheckboxTheme';
import { muiDialogTheme } from './components/muiDialogTheme';
import { muiRadioGroupTheme } from './components/muiRadioGroupTheme';
import { muiInputTheme } from './components/muiInputTheme';

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface Palette {
    kit: {
      main: React.CSSProperties['color'];
      accent: React.CSSProperties['color'];
      accent2: React.CSSProperties['color'];
      infographic: {
        grey: React.CSSProperties['color'];
        orange: React.CSSProperties['color'];
        darkPurple: React.CSSProperties['color'];
      };
      text: {
        main: React.CSSProperties['color'];
        secondary: React.CSSProperties['color'];
        accent: React.CSSProperties['color'];
        h: React.CSSProperties['color'];
        orange: React.CSSProperties['color'];
        error: React.CSSProperties['color'];
        success: React.CSSProperties['color'];
        disabled: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
        mainInverse: React.CSSProperties['color'];
        secondaryInverse: React.CSSProperties['color'];
      };
      background: {
        purple: React.CSSProperties['color'];
        white: React.CSSProperties['color'];
        gray: React.CSSProperties['color'];
        darkgray: React.CSSProperties['color'];
        middlepurple: React.CSSProperties['color'];
        lightpurple: React.CSSProperties['color'];
        black: React.CSSProperties['color'];
        success: React.CSSProperties['color'];
        error: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
      };
      link: {
        main: React.CSSProperties['color'];
        inverse: React.CSSProperties['color'];
        inverseWhite: React.CSSProperties['color'];
      };
      stroke: {
        gray: React.CSSProperties['color'];
        turquoise: React.CSSProperties['color'];
        purple: React.CSSProperties['color'];
        black: React.CSSProperties['color'];
      };
      border: {
        gradient: {
          start: React.CSSProperties['color'];
          end: React.CSSProperties['color'];
        };
      };
      button: {
        primary: {
          active: React.CSSProperties['color'];
        };
      };
    };
  }
  // eslint-disable-next-line no-unused-vars
  interface PaletteOptions {
    kit: {
      main: React.CSSProperties['color'];
      accent: React.CSSProperties['color'];
      accent2: React.CSSProperties['color'];
      infographic: {
        grey: React.CSSProperties['color'];
        orange: React.CSSProperties['color'];
        darkPurple: React.CSSProperties['color'];
      };
      text: {
        main: React.CSSProperties['color'];
        secondary: React.CSSProperties['color'];
        accent: React.CSSProperties['color'];
        h: React.CSSProperties['color'];
        orange: React.CSSProperties['color'];
        error: React.CSSProperties['color'];
        success: React.CSSProperties['color'];
        disabled: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
        mainInverse: React.CSSProperties['color'];
        secondaryInverse: React.CSSProperties['color'];
      };
      background: {
        purple: React.CSSProperties['color'];
        white: React.CSSProperties['color'];
        gray: React.CSSProperties['color'];
        darkgray: React.CSSProperties['color'];
        middlepurple: React.CSSProperties['color'];
        lightpurple: React.CSSProperties['color'];
        black: React.CSSProperties['color'];
        success: React.CSSProperties['color'];
        error: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
      };
      link: {
        main: React.CSSProperties['color'];
        inverse: React.CSSProperties['color'];
        inverseWhite: React.CSSProperties['color'];
      };
      stroke: {
        gray: React.CSSProperties['color'];
        turquoise: React.CSSProperties['color'];
        purple: React.CSSProperties['color'];
        black: React.CSSProperties['color'];
      };
      border: {
        gradient: {
          start: React.CSSProperties['color'];
          end: React.CSSProperties['color'];
        };
      };
      button: {
        primary: {
          active: React.CSSProperties['color'];
        };
      };
    };
  }

  // eslint-disable-next-line no-unused-vars
  interface BreakpointOverrides {
    xxl: true;
  }
}

const KIT_MAIN = '#4520AB';
const KIT_ACCENT = '#FFBC5B';
const KIT_ACCENT2 = '#29EDFF';

const KIT_INFOGRAPHIC_ORANGE = '#FABA5A';
const KIT_INFOGRAPHIC_GREY = '#C4C4C4';
const KIT_INFOGRAPHIC_DARKPURPLE = '#281658';

const KIT_TEXT_MAIN = '#0F0F0F';
const KIT_TEXT_SECONDARY = '#666666';
const KIT_TEXT_ACCENT = '#29EDFF';
const KIT_TEXT_H = '#4520AB';
const KIT_TEXT_ORANGE = '#FFBC5B';
const KIT_TEXT_ERROR = '#FF6363';
const KIT_TEXT_SUCCESS = '#14C366';
const KIT_TEXT_DISABLED = '#D4D1DD';
const KIT_TEXT_WARNING = '#FF9F14';
const KIT_TEXT_MAIN_INVERSE = '#FFFFFF';
const KIT_TEXT_SECONDARY_INVERSE = '#FFFFFF66';

const KIT_BACKGROUND_PURPLE = '#4520AB';
const KIT_BACKGROUND_WHITE = '#FFFFFF';
const KIT_BACKGROUND_GRAY = '#F8F6FC';
const KIT_BACKGROUND_DARKGRAY = '#EFECF5';
const KIT_BACKGROUND_MIDDLEPURPLE = '#E9E9FF';
const KIT_BACKGROUND_LIGHTPURPLE = '#F4F2FA';
const KIT_BACKGROUND_BLACK = '#111111';
const KIT_BACKGROUND_SUCCESS = '#EBFFF4';
const KIT_BACKGROUND_ERROR = '#FFF1F1';
const KIT_BACKGROUND_WARNING = '#FAF3E9';

const KIT_LINK_MAIN = '#4520AB';
const KIT_LINK_INVERSE = '#FFBC5B';
const KIT_LINK_INVERSE_WHITE = '#FFFFFF';

const KIT_STROKE_GRAY = '#D9D9D9';
const KIT_STROKE_TURQUOISE = '#29EDFF';
const KIT_STROKE_PURPLE = '#4520AB';
const KIT_STROKE_BLACK = '#0F0F0F';

const KIT_BUTTON_RPIMARY_ACTIVE = '#FFAE39';

const KIT_BORDER_GRADIENT_START = '#956FFF';
const KIT_BORDER_GRADIENT_END = '#29EDFF';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 535,
      md: 900,
      lg: 1200,
      xl: 1440,
      xxl: 1536,
    },
  },
  palette: {
    kit: {
      main: KIT_MAIN,
      accent: KIT_ACCENT,
      accent2: KIT_ACCENT2,
      infographic: {
        grey: KIT_INFOGRAPHIC_GREY,
        orange: KIT_INFOGRAPHIC_ORANGE,
        darkPurple: KIT_INFOGRAPHIC_DARKPURPLE,
      },
      text: {
        main: KIT_TEXT_MAIN,
        secondary: KIT_TEXT_SECONDARY,
        accent: KIT_TEXT_ACCENT,
        h: KIT_TEXT_H,
        orange: KIT_TEXT_ORANGE,
        error: KIT_TEXT_ERROR,
        success: KIT_TEXT_SUCCESS,
        disabled: KIT_TEXT_DISABLED,
        warning: KIT_TEXT_WARNING,
        mainInverse: KIT_TEXT_MAIN_INVERSE,
        secondaryInverse: KIT_TEXT_SECONDARY_INVERSE,
      },
      background: {
        purple: KIT_BACKGROUND_PURPLE,
        white: KIT_BACKGROUND_WHITE,
        gray: KIT_BACKGROUND_GRAY,
        darkgray: KIT_BACKGROUND_DARKGRAY,
        middlepurple: KIT_BACKGROUND_MIDDLEPURPLE,
        lightpurple: KIT_BACKGROUND_LIGHTPURPLE,
        black: KIT_BACKGROUND_BLACK,
        success: KIT_BACKGROUND_SUCCESS,
        error: KIT_BACKGROUND_ERROR,
        warning: KIT_BACKGROUND_WARNING,
      },
      link: {
        main: KIT_LINK_MAIN,
        inverse: KIT_LINK_INVERSE,
        inverseWhite: KIT_LINK_INVERSE_WHITE,
      },
      stroke: {
        gray: KIT_STROKE_GRAY,
        turquoise: KIT_STROKE_TURQUOISE,
        purple: KIT_STROKE_PURPLE,
        black: KIT_STROKE_BLACK,
      },
      border: {
        gradient: {
          start: KIT_BORDER_GRADIENT_START,
          end: KIT_BORDER_GRADIENT_END,
        },
      },
      button: {
        primary: {
          active: KIT_BUTTON_RPIMARY_ACTIVE,
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    desktopH0: {
      fontSize: 72,
      fontFamily: 'RF Rostin',
      fontWeight: 700,
    },
    desktopH1: {
      fontSize: 60,
      fontFamily: 'RF Rostin',
      fontWeight: 700,
    },
    desktopH2: {
      fontSize: 46,
      fontFamily: 'RF Rostin',
      fontWeight: 400,
    },
    desktopH3: {
      fontSize: 34,
      fontFamily: 'RF Rostin',
      fontWeight: 400,
      letterSpacing: '-2px',
    },
    desktopH4: {
      fontSize: 22,
      fontFamily: 'RF Rostin',
      fontWeight: 400,
      letterSpacing: '-0.07em',
    },
    desktopH5: {
      fontSize: 18,
      fontFamily: 'RF Rostin',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    desktopP1: {
      fontSize: 18,
      fontFamily: 'Inter',
    },
    desktopP2: {
      fontSize: 16,
      fontFamily: 'Inter',
    },
    desktopP3: {
      fontSize: 14,
      fontFamily: 'Inter',
    },
    desktopP3Helper: {
      fontSize: 12,
      fontFamily: 'Inter',
    },
    mobileH1: {
      fontFamily: 'RF Rostin',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '46px',
      lineHeight: '119%',
      letterSpacing: '-0.09em',
      textTransform: 'uppercase',
    },
    mobileH2: {
      fontFamily: 'RF Rostin',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '34px',
      lineHeight: '120%',
      letterSpacing: '-0.07em',
    },
    mobileH3: {
      fontFamily: 'RF Rostin',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '120%',
      letterSpacing: '-0.07em',
    },
    mobileH4: {
      fontFamily: 'RF Rostin',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '120%',
      letterSpacing: '-0.07em',
    },
    mobileH5: {
      fontFamily: 'RF Rostin',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '130%',
      letterSpacing: '-0.06em',
      textTransform: 'uppercase',
    },
    mobileP1: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      letterSpacing: '0.02em',
    },
    mobileP2: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '135%',
      letterSpacing: '0.01em',
    },
    mobileP3: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '125%',
      letterSpacing: '0.03em',
    },
    mobileHelper: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '130%',
      letterSpacing: '0.03em',
    },
  },
  components: {
    ...muiCssBaselineTheme,
    ...muiTypographyTheme,
    ...muiAppBarTheme,
    ...muiButtonTheme,
    ...muiMenuTheme,
    ...muiMenuItemTheme,
    ...muiLinkTheme,
    ...muiChipTheme,
    ...muiStepLabelTheme,
    ...muiTabTheme,
    ...muiTabsTheme,
    ...muiLinearProgressTheme,
    ...muiListTheme,
    ...muiListItemTheme,
    ...muiPaperTheme,
    ...muiAccordionTheme,
    ...muiAccordionSummaryTheme,
    ...muiAccordionDetailsTheme,
    ...muiTextFieldTheme,
    ...muiFormTheme,
    ...muiDialogTheme,
    ...muiCheckboxTheme,
    ...muiRadioGroupTheme,
    ...muiSelectTheme,
    ...muiInputTheme,
    ...muiInputLabelTheme,
  },
});
