import { SvgIcon, SvgIconProps } from '@mui/material';

export function CheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      {...props}
      sx={{ fill: 'none' }}
    >
      <circle cx="10.1812" cy="10.2158" r="10" fill="#29EDFF" />
      <path
        d="M5.51416 10.5182L8.43083 13.5485L14.8475 6.88184"
        stroke="#4520AB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
