import { Components, Theme } from '@mui/material';

export const muiMenuTheme: Components<Theme> = {
  MuiMenu: {
    styleOverrides: {
      root: {
        '.MuiMenu-paper': {
          boxShadow: '0px 4px 15px rgba(142, 141, 208, 0.16)',
          borderRadius: '0px 0px 4px 4px',
        },
        '.MuiList-root': {
          paddingTop: 12,
          paddingBottom: 12,
          paddingRight: 16,
          paddingLeft: 16,
        },
      },
    },
  },
};
