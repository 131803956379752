import { Components, Theme } from '@mui/material';

export const muiListTheme: Components<Theme> = {
  MuiList: {
    styleOverrides: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
};
