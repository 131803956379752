import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { styles } from './styles';
import { CheckIcon } from './icons/CheckIcon';
import { RubleIcon } from './icons/RubleIcon';

export default function Balance({ points }) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <>
      {points && (
        <Box>
          <Box sx={styles.container}>
            <RubleIcon sx={styles.rubleSign} />
            <Typography
              variant={isSm ? 'mobileP2' : 'desktopP2'}
              sx={styles.title}
            >
              Всего баллов на счету
            </Typography>
            <Box sx={styles.pointsContainer}>
              <CheckIcon />
              <Typography variant={isSm ? 'mobileH2' : 'desktopH2'}>
                {points}
              </Typography>
            </Box>
            <Box>
              <Typography variant={isSm ? 'mobileP3' : 'desktopP3'}>
                (1 балл=1 рубль)
              </Typography>
            </Box>
            <Box sx={styles.expireBlock}>
              <Typography variant={isSm ? 'mobileP3' : 'desktopP3'}>
                Срок действия баллов -
                <br />6 месяцев с даты зачисления
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
