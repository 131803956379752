import {
  SagaReturnType,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { getRoistatVisitCookie } from '../../helpers/getRoistatVisitCookie';
import { getUtmCookie } from '../../helpers/utmCookie';
import { subscribe } from '../reducers/footerSubscriptionSlice';
import { AppState } from '../store';

function* doSubscribe(email: string) {
  const subscribeResponse: SagaReturnType<typeof fetch> = yield call(
    fetch,
    `${process.env.REACT_APP_BACKEND_URL}/api/crm/oldFormatLead`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        roistatVisit: getRoistatVisitCookie(),
        pipelineType: 'subscribeNew',
        utm: getUtmCookie(),
      }),
    },
  );

  return subscribeResponse;
}

function* subscribeTriggerWorker() {
  const emailValue: string = yield select(
    (state: AppState) => state.footerSubscription.emailText,
  );

  yield put(subscribe.request());

  try {
    yield doSubscribe(emailValue);
    yield put(subscribe.success());
  } catch (error) {
    console.error('Failed to subsribe: ', error);
    yield put(subscribe.failure());
  }

  yield put(subscribe.fulfill());
}

export function* footerSubscriptionWatcher() {
  yield takeLatest(subscribe.TRIGGER, subscribeTriggerWorker);
}
