import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import styles from './styles';
import BalanceItem, { Item } from '../BalanceItem/BalanceItem';

interface BalanceHistoryProps {
  items: Item[];
}

function BalanceHistory({ items }: BalanceHistoryProps) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Box>
        <Box>
          <Typography
            variant={isSm ? 'mobileP2' : 'desktopP2'}
            sx={styles.balanceTitle}
          >
            История начисления/списания баллов:
          </Typography>
        </Box>
        <Box sx={styles.mainItemsContainer}>
          <Box sx={styles.headerBlock}>
            <Box>Дата и время</Box>
            <Box>Операция</Box>
            <Box>Количество</Box>
            <Box>Комментарий</Box>
          </Box>
          <Box sx={styles.itemsContainer}>
            {items?.map((item, index) => (
              <BalanceItem key={index} item={item} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BalanceHistory;
