import { Components, Theme } from '@mui/material';

export const muiAccordionTheme: Components<Theme> = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
  },
};
