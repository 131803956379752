import { Theme } from '@mui/material';

export const styles = {
  container: (theme: Theme) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px 16px 24px',
    isolation: 'isolate',
    background: '#FFF',
    borderRadius: '16px',
    width: '424px',
    color: '#666',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '24px 16px',
    },
  }),
  rubleSign: (theme: Theme) => ({
    position: 'absolute',
    width: '106px',
    height: '118px',
    right: '16px',
    top: '24px',
    color: 'kit.background.purple',
    [theme.breakpoints.down('sm')]: {
      height: '81px',
      width: '73px',
    },
  }),
  title: {
    alignSelf: 'stretch',
  },
  pointsContainer: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    margin: '10px 0 3px',
    gap: '12px',
    color: 'kit.text.main',
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0 8px',
    },
  }),
  expireBlock: {
    marginTop: '16px',
  },
};
