import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import { ModalViewType } from '../MainModal/MainModal';
import { styles } from './styles';

interface MainModalProps {
  emailValue: string;
  setField: (fieldName: string, value: string) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
  emailError: { isError: boolean; message: string };
  passwordValue: string;
  passwordError: { isError: boolean; message: string };
  setModalView: Dispatch<SetStateAction<ModalViewType>>;
  isLoading: boolean;
  handleLogin: () => void;
  setModalFieldsErrorState: (state: object) => void;
  setModalFieldsState: (state: object) => void;
  initRegisterModalFieldsErrorState: object;
  toggleView: (view: string) => void;
}
export default function LoginModal(props: MainModalProps) {
  const theme = useTheme();

  const {
    emailValue,
    emailError,
    passwordValue,
    passwordError,
    setField,
    onBlurHandler,
    isLoading,
    handleLogin,
    toggleView,
  } = props;
  const [visibilityPass, setVisibilityPass] = useState(false);
  useEffect(() => {
    if (!passwordValue) {
      setVisibilityPass(false);
    }
  }, [passwordValue]);

  return (
    <>
      <DialogTitle>
        <Box sx={styles.loginBox}>
          <>
            <Typography sx={styles.typography} variant="desktopH3">
              Войти
            </Typography>
            <Box sx={styles.textBox}>
              <Box sx={{ mr: 2 }}>
                <Typography
                  variant="desktopP1"
                  sx={styles.registrationTypography(theme)}
                >
                  Вы не зарегистрированы?{' '}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={(theme) => ({
                    letterSpacing: -0.5,
                    color: 'kit.text.h',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 14,
                    },
                    cursor: 'pointer',
                  })}
                  onClick={() => toggleView('REGISTER')}
                >
                  {' '}
                  Регистрация
                </Typography>
              </Box>
            </Box>
          </>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'}>
          <>
            <TextField
              variant="standard"
              sx={{ my: 1 }}
              id="email"
              label="Email"
              error={true}
              placeholder="test@example.com"
              value={emailValue}
              fullWidth
              onChange={(e) => setField(e.target.id, e.target.value.trim())}
              onBlur={onBlurHandler}
              helperText={emailError.isError && emailError.message}
            />
            <Grid item sx={{ position: 'relative' }}>
              <TextField
                variant="standard"
                sx={{ my: 1 }}
                type={!visibilityPass ? 'password' : ''}
                id="password"
                error={true}
                label="Пароль"
                placeholder="*******"
                fullWidth
                value={passwordValue}
                onChange={(e) => setField(e.target.id, e.target.value)}
                onBlur={onBlurHandler}
                helperText={passwordError.isError && passwordError.message}
              />
              {visibilityPass ? (
                <VisibilityIcon
                  sx={styles.passwordIcon(passwordValue)}
                  onClick={() =>
                    setVisibilityPass((prev) => (passwordValue ? !prev : prev))
                  }
                />
              ) : (
                <VisibilityOffIcon
                  sx={styles.passwordIcon(passwordValue)}
                  onClick={() =>
                    setVisibilityPass((prev) => (passwordValue ? !prev : prev))
                  }
                />
              )}
            </Grid>
            <Box sx={styles.forgotPassword}>
              <Typography
                sx={styles.forgetPasswordTypography(theme)}
                onClick={() => toggleView('REQUEST_PASSWORD_LINK')}
              >
                Забыли пароль?
              </Typography>
            </Box>
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={styles.buttonBox}>
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <Button variant="kitPrimary" onClick={handleLogin}>
              Войти
            </Button>
          )}
        </Box>
      </DialogActions>
    </>
  );
}
